import { is } from "puppit-shared/fn";
import {HAS_VOTED, IS_ELIGIBLE, VOTE_IS_OPEN} from "./dict";
import {trileanValueOf} from "./fn";

export const voteIsOpen = is( VOTE_IS_OPEN, true )
export const voteIsClosed = is( VOTE_IS_OPEN, false )
export const hasVoted = is( HAS_VOTED, true );
export const hasNotVoted = is( HAS_VOTED, false );
export const canVote = vo => voteIsOpen( vo ) && hasNotVoted( vo ) && trileanValueOf( IS_ELIGIBLE )( true );

export const isClosingSoon = differenceInDays => differenceInDays < 5 && differenceInDays > 0;
