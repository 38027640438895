import React from "react";
import {doNothing} from "puppit-shared/fn";
import {Modal} from "react-bootstrap";
import {loggedInAtom, userAtom} from "../../fn/atomic";
import {useAtom} from "jotai";
import {profileVerified} from "../../fn/fn";
import VerifyFlow from "../verify/VerifyFlow";
import {IS_SHOWING, ON_HIDE} from "../../fn/dict";
import LoginFlow from "./LoginFlow";

const LoginModal = props => {

    const isShowing = props[IS_SHOWING]
    const onHide = props[ON_HIDE] || doNothing;
    const [user,] = useAtom(userAtom);
    const [isLoggedIn,] = useAtom(loggedInAtom);

    let flow = isLoggedIn.isTrue() && profileVerified(user).isNotTrue() ? <VerifyFlow isModal={true}/> : <LoginFlow isModal={true}/>

    return (

        <Modal className="shadow-sm min-vh-80"
               animation={true}
               onEscapeKeyDown={onHide}
               centered show={isShowing}
               onHide={onHide}>

            <Modal.Body>
                {flow}
            </Modal.Body>


        </Modal>);
}

export default LoginModal
