import React from "react";
import {valueOfOr} from "puppit-shared/fn";
import {VOTE, VOTE_PCT_AGAINST, VOTE_PCT_IN_FAVOUR} from "../../fn/dict";

const VoteResultsPanel = params => {

    const vote = valueOfOr(VOTE, {})(params)

    const yesPct = valueOfOr(VOTE_PCT_IN_FAVOUR, 50)(vote);
    const noPct = valueOfOr(VOTE_PCT_AGAINST, 50)(vote);

    return (
        <div>
            <div className="progress mb-2" role="progressbar"
                 aria-valuemin="0" aria-valuemax="100" style={{height: "2.5em"}}>
                <div className="progress-bar bg-primary p-2" style={{width: yesPct + "%"}}>Yes</div>
            </div>

            <div className="progress mb-2" role="progressbar"
                 aria-valuemin="0" aria-valuemax="100" style={{height: "2.5em"}}>
                <div className="progress-bar bg-info" style={{width: noPct + "%"}}>No</div>
            </div>
            <div className='text-muted small'>{yesPct}% of people
                have voted for this issue, and {noPct}% have voted against.
            </div>
        </div>
    );

}
export default VoteResultsPanel
