import {PUBLIC_VOTE_PAGE} from "./dict";


export const pathIs = href => path =>  href.toLowerCase().endsWith( path );
export const pathIsNot = href => path => !href.toLowerCase().endsWith( path );
export const thePublicVotePage = internalId => PUBLIC_VOTE_PAGE.replace( ":internalId", internalId );

export const HOME_PAGE = "/";
export const DIRECT_DEMOCRACY_RIGHT_NOW_URL = "https://ddrightnow.com/";
export const BV_EXPLAINER_VID_URL = "https://blocvote.sgp1.cdn.digitaloceanspaces.com/media/web/blocvote-animation.mp4";
export const directDemocracyRightNowLink = () => DIRECT_DEMOCRACY_RIGHT_NOW_URL;
export const blocvoteExplainerVideoUrl = () => BV_EXPLAINER_VID_URL;
export const SETTINGS_PAGE = "/settings";
export const ERROR_PAGE = "/error";

export const ACCOUNT_DETAILS_PAGE = "/settings/account-details";
export const MANAGE_VOTES_PAGE = "/settings/manage-votes";
export const MANAGE_CONNECTIONS_PAGE = "/settings/manage-connections";
export const CREATE_VOTE_FLOW_PAGE = "/settings/create-vote"
export const PUBLISH_VOTE_FLOW_PAGE = "/settings/publish-vote"
