import React, {useEffect, useState} from "react";
import {fetchVotes, mapToData} from "../fn/api";
import {ascending, distinctOn, isNotProcessing, mergeLeft, minusOne, plusOne, toKeyedDiv} from "../fn/fn";
import {HAS_VOTED, INTERNAL_ID, PARTNER_ID, VOTE_CLOSE_TIMESTAMP} from "../fn/dict";
import {is, isEmpty} from "puppit-shared/fn";
import {processingAtom} from "../fn/atomic";
import {useAtom} from "jotai";
import VoteCard from "./vote/VoteCard";
import PageHeading from "./PageHeading";

const Votes = () => {

        const [votes, setVotes] = useState([]);
        const [, setPartners] = useState([]);
        const [, setProcessing] = useAtom(processingAtom)

        useEffect(() => {
            setProcessing(plusOne);
            const abort = new AbortController();
            fetchVotes(abort).then(mapToData)
                .then(setVotes)
                .finally(() => setProcessing(minusOne))

            return () => abort.abort();
            // eslint-disable-next-line
        }, []);

        useEffect(() => {

            const vo = (votes || []).filter(distinctOn(PARTNER_ID, []));
            setPartners(vo);

        }, [votes])

        const onAfterVote = vote => {
            setVotes(izzues => izzues.map(izzue => (izzue[INTERNAL_ID] === vote[INTERNAL_ID]) ? mergeLeft(izzue, vote) : izzue));
        }

        if (isEmpty(votes) && isNotProcessing(setProcessing))
            return <div className='mt-5 pt-5 text-center text-muted'><i
                className="display-1 bi bi-check-circle text-success"></i><br/><br/>
                You're up to date, nothing outstanding.</div>

        const hasVoted = is(HAS_VOTED, true)
        const hasNotVoted = is(HAS_VOTED, false)

        return (
            <>
                <PageHeading icon={<i className='rounded-circle p-2 bg-light bi bi-clock-history text-primary'/>}
                             heading='Votes'
                             lead='Have your say on the issues that affect you and your loved ones.'/>
                <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-3">
                    {votes.filter(hasNotVoted)
                        .sort(ascending(VOTE_CLOSE_TIMESTAMP))
                        .map(vo => <VoteCard data={vo} onAfterVote={onAfterVote}/>)
                        .map(toKeyedDiv("col mb-3", "voted"))}
                    {votes.filter(hasVoted)
                        .sort(ascending(VOTE_CLOSE_TIMESTAMP))
                        .map(vo => <VoteCard data={vo} onAfterVote={onAfterVote}/>)
                        .map(toKeyedDiv("col mb-3", "voted"))}
                </div>
            </>
        )
    }
;

export default Votes;
