import React from "react";
import { useAtom } from "jotai";
import { valueOf } from "puppit-shared/fn";
import { voteAccountValidAtom } from "../../fn/atomic";
import { ON_HIDE } from "../../fn/dict";
import { trileanWhen } from "../../fn/tnew";
import ActivationCodeDisplay from "./ActivationCodeDisplay";
import ActivationCodeUpdate from "./ActivationCodeUpdate";

const ActivationCodeModal = props => {

    const hide = valueOf( ON_HIDE )( props )
    const [voteAccountStatus,] = useAtom( voteAccountValidAtom );

    return trileanWhen(
        <ActivationCodeDisplay onHide={hide}/>,
        <></>,
        <ActivationCodeUpdate onHide={hide}/>
    )( voteAccountStatus )

}

export default ActivationCodeModal

