import { Modal } from "react-bootstrap";
import React from "react";
import { valueOf } from "puppit-shared/fn";
import { HIDE, IS_SHOWING } from "../../fn/dict";

const MultiModal = props => {
    const isShowing = valueOf( IS_SHOWING )( props );
    const hide = valueOf( HIDE )( props )

    return (
        <Modal className="shadow-sm"
               centered
               show={isShowing}
               onHide={hide}
               animation={true}>

            <Modal.Body>
                {props.children}
            </Modal.Body>

        </Modal>
    )
}

export default MultiModal