import { Link } from "react-router-dom";
import { PARTNERS_PAGE, HISTORY_PAGE, VOTES_PAGE } from "../fn/dict";
import { pathIsNot } from "../fn/navigation-fn";
import { processingAtom } from "../fn/atomic";
import { useAtom } from "jotai";

const NavBottom = () => {

    const [processing,] = useAtom( processingAtom);

    const currentPathIsNot = pathIsNot( window.location.href );

    const linkColor = page => currentPathIsNot( page ) ? " text-dark " : " logo ";

    return (
            <nav className="navbar fixed-bottom bg-light pb-3">
                <div className="container d-flex justify-content-around align-content-center p-0 my-2">

                    <Link to={VOTES_PAGE} className={"h5 m-0 text-center text-decoration-none " + (processing ? 'disabled-link':'')}>
                        <div><i className={"bi bi-box " + linkColor( VOTES_PAGE )}/></div>
                        <div className="small text-muted mt-1 text-dark">votes</div>
                    </Link>

                    <Link to={PARTNERS_PAGE} className={"h5 m-0 text-center text-decoration-none " + (processing ? 'disabled-link':'')}>
                        <div><i className={"bi bi-star " + linkColor( PARTNERS_PAGE )}/></div>
                        <div className="small text-muted mt-1 text-dark">partners</div>
                    </Link>

                    <Link to={HISTORY_PAGE} className={"h5 m-0 text-center text-decoration-none " + (processing ? 'disabled-link':'')}>
                        <div><i className={"bi bi-clock-history " + linkColor( HISTORY_PAGE )}/></div>
                        <div className="small text-muted mt-1 text-dark">history</div>
                    </Link>
                </div>
            </nav>
    );
}

export default NavBottom;
