export const SESSION_HEADER = 'X-Session-Token';

export const HOME_PAGE = "/";
export const VOTE_PAGE = "/vote/:internalId";
export const VOTES_PAGE = "/votes";
export const IS_MODAL = "isModal";
export const ON_CONTINUE = "onContinue";
export const PUBLIC_VOTE_PAGE = "/vote/:internalId";
export const PARTNERS_PAGE = "/partners";
export const HISTORY_PAGE = "/history";
export const SUCCESS = "success";
export const PROFILE_COMPLETE = "profileComplete";
export const PROFILE_VERIFIED = "profileVerified";
export const REJECTION_REASON = "rejectionReason";
export const STATUS = "status";
export const VOTE = "vote";
export const ON_LOGIN = "onLogin";
export const ROLES = "roles";

export const CAN_VERIFY = "canVerify";
export const CAN_EDIT = "canEdit";
export const KYC_OPS_PAGE = "/operations/kyc"

export const ISSUE_PARTNER = "issuePartner";
export const FORM = "form";
export const REVIEW = "review";

export const ISSUE_CREATOR = "issueCreator"
export const STATE = "state";
export const IS_SHOWING = "isShowing";
export const HIDE = "hide";
export const ON_HIDE = "onHide";
export const PARTNER_PAGE = "/partner/:partnerId";
export const ON_EDIT = "onEdit";
export const ON_PUBLISH = "onPublish";

export const ON_DELETE = "onDelete";
export const PROCESSING = "processing";
export const SET_REDIRECT_URL = "setRedirectUrl";
export const ISSUES = "issues";
export const UK = "UK";
export const COUNTRY_CODE = "countryCode";
export const MIME_TYPE = "mimeType";
export const ZIP_CODE = "zipcode";
export const ZONE_NAME = "zoneName";
export const ZONE_ID = "zoneId";
export const ZONE_ORDER = "zoneOrder";
export const ON_AFTER_VOTE = "onAfterVote";
export const UNKNOWN_LOCATION = "unknownLocation";
export const UNSPECIFIED_LOCATION = "unspecifiedLocation";

export const ISSUE_SUMMARY = "issueSummary";
export const ISSUE_LINK = "issueLink";
export const ISSUE_IMAGE = "issueImage";
export const VOTE_CLOSE_TIMESTAMP = "voteCloseTimestamp";

export const IN_FAVOUR = "inFavour";
export const AGAINST = "against";
export const VOTE_IS_OPEN = "voteIsOpen";
export const IS_ELIGIBLE = "isEligible";
export const VOTE_PCT_AGAINST = "votePctAgainst";
export const VOTE_PCT_IN_FAVOUR = "votePctInFavour";
export const ISSUE_QUESTION = "issueQuestion";
export const ISSUE_STATE = 'issueState';
export const PUBLISHED = 'published';
export const DRAFT = 'draft';
export const REPLY = 'reply';

export const INTERNAL_ID = "internalId";
export const SESSION_TOKEN = "sessionToken";
export const BLOCVOTE_DEVICE_CONTEXT = "blocvoteUserContext";
export const VOTE_ACCOUNT_ADDRESS = "voteAccountAddress";
export const VOTE_ACCOUNT_CYPHER = "voteAccountCypher";
export const DOCUMENT_ID = "documentId";
export const ADMIN = "admin";
export const OLD_VOTE_ACCOUNT_CYPHER = "oldVoteAccountCypher";

export const PARTNER_TYPE = "partnerType"
export const PARTNER_CONTACTS = "partnerContacts";
export const PARTNER_CONTACT_TYPE = "partnerContactType";
export const PARTNER_CONTACT_URL = "partnerContactUrl";
export const PARTNER_URL = "partnerUrl";
export const PARTNER_CONTACT = "partnerContact";

export const INDEPENDENT = "independent";
export const DATA = "data";
export const PROFILE_ID = "profileId";
export const PARTNER_ID = "partnerId";
export const CONNECTED = "connected";
export const MESSAGE = "message";
export const PARTNER_NAME = "partnerName";
export const PARTNER_TITLE = "partnerTitle";
export const PARTNER_BIO = "partnerBio";
export const PARTNER_IMAGE = "partnerImage";

export const PARTNER = 'partner';
export const HAS_VOTED = "hasVoted";
export const CLOSING_SOON = "closingSoon";
export const IN_FAVOUR_RESULT = "inFavourResult";
export const AGAINST_RESULT = "againstResult";

export const IS_OPEN = "isOpen";
export const CLOSE = "close";
