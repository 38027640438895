import React, {useRef, useState} from "react";
import {coalesce, doNothing, isBlank, isDefined, isNotDefined, valueOfOr} from "puppit-shared/fn";
import {Navigate} from "react-router-dom";
import {useAtom} from "jotai";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import {deviceIdAtom, processingAtom, userAtom} from "../../fn/atomic";
import {ERROR_PAGE} from "../../fn/navigation-fn";
import {captchaKey, sendOtp} from "../../fn/api";
import {isProcessing, minusOne, plusOne} from "../../fn/fn";
import {EMAIL} from "puppit-shared/dict";
import {IS_MODAL} from "../../fn/dict";

const Login = props => {

    const next = coalesce(props.next, doNothing);

    const [, setUser] = useAtom(userAtom);
    const [deviceId,] = useAtom(deviceIdAtom);
    const [processing, setProcessing] = useAtom(processingAtom);
    const [redirect, setRedirect] = useState(null);
    const [captcha, setCaptcha] = useState();
    const [email, setEmail] = useState(null);

    const isModal = valueOfOr(IS_MODAL, false)(props);

    const emailIsValid = addr => /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(addr)
    const emailIsNotValid = addr => isNotDefined(addr) || !emailIsValid(addr)

    const captchaRef = useRef(null);

    const redirectToErrorPage = () => setRedirect(ERROR_PAGE)

    const updateEmail = e => {
        const value = isBlank(e.target.value) ? undefined : e.target.value.toLowerCase().trim();
        setEmail(value);
    }

    const handleClick = () => {

        setProcessing(plusOne);

        const emailLower = email.toLowerCase().trim();
        setUser({[EMAIL]: emailLower});

        sendOtp(emailLower, deviceId, captcha)
            .then(next)
            .catch(redirectToErrorPage)
            .finally(() => setProcessing(minusOne));
    }

    if (isDefined(redirect))
        return <Navigate to={redirect}/>

    const handleVerificationSuccess = (token, ekey) => setCaptcha(token);
    const handleVerificationError = () => captchaRef.current?.resetCaptcha();
    const handleVerificationExpire = () => captchaRef.current?.resetCaptcha();

    if (isProcessing(processing))
        return <div></div>

    return (
        <div className={isModal ? '' : 'pt-3 mt-3'}>
            <div className="minw-70 d-flex align-items-center justify-content-center">

                <div>
                    <h1 className="display-1 logo text-center"><i className="bi bi-box"/></h1>
                    <div className="h3 text-center mb-4">Log in / Sign up</div>

                    <input onChange={updateEmail}
                           type="email"
                           placeholder="email"
                           className="form-control"
                           disabled={isProcessing(processing)}/>

                    <div className="d-grid gap-2 d-block my-3">

                        <HCaptcha
                            sitekey={captchaKey}
                            onVerify={handleVerificationSuccess}
                            onError={handleVerificationError}
                            onExpire={handleVerificationExpire}
                        />

                    </div>

                    <button type="button"
                            onClick={handleClick}
                            disabled={processing || isNotDefined(captcha) || emailIsNotValid(email)}
                            className="btn btn-block btn-outline-dark">
                        Continue
                    </button>

                    <div className="mt-4 text-center small text-muted">By continuing you accept BLOCVOTE's<br/>
                        <a target="_legal"
                           href="https://blocvote.org/terms-of-service">terms&nbsp;of&nbsp;service</a>.
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
