import './App.css';
import ReactDOM from "react-dom/client";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import NotFound from "./components/NotFound";
import {
    CREATE_VOTE_FLOW_PAGE,
    ERROR_PAGE,
    HOME_PAGE,
    MANAGE_CONNECTIONS_PAGE,
    MANAGE_VOTES_PAGE,
    PUBLISH_VOTE_FLOW_PAGE,
    SETTINGS_PAGE
} from "./fn/navigation-fn";
import {
    deviceContextAtom,
    loggedInAtom,
    processingAtom, profileReloadAtom,
    sessionTokenAtom,
    TO_USER_DEFAULT,
    userAtom,
    voteAccountValidAtom
} from "./fn/atomic";
import { useAtom } from "jotai";
import React, { useEffect } from "react";
import { checkVotingAccount, fetchUserProfile, mapToData, updateAxiosClient } from "./fn/api";
import Layout from "./components/Layout";
import ErrorPage from "./components/ErrorPage";
import { cloak, clog, isNotDefined, valueOf } from "puppit-shared/fn";
import { Trilean } from "dahai-mvl/dist/main/ts/Trilean";
import Partners from "./components/Partners";
import {
    HISTORY_PAGE,
    KYC_OPS_PAGE,
    PARTNER_PAGE,
    PARTNERS_PAGE,
    PUBLIC_VOTE_PAGE,
    STATUS,
    VOTES_PAGE
} from "./fn/dict";
import History from "./components/History";
import KycOperations from "./components/KycOperations";
import Partner from "./components/Partner";
import ManageConnections from "./components/ManageConnections";
import ManageVotes from "./components/ManageVotes";
import { compose, minusOne, plusOne } from "./fn/fn";
import CreateVoteFlow from "./components/CreateVoteFlow";
import PublishVoteFlow from "./components/PublishVoteFlow";
import Secure from "./components/Secure";
import { extractVoteAccountCypher } from "./fn/user";
import Settings from "./components/settings/Settings";
import { coalesceTrilean } from "./fn/tnew";
import Votes from "./components/Votes";
import PublicVotePage from "./components/vote/PublicVotePage";

const App = () => {

    // if there is a valid session token then continue else redirect to the
    // sign-in signup page.

    const [, setUser] = useAtom( userAtom )
    const [processing, setProcessing] = useAtom( processingAtom )
    const [sessionToken, setSessionToken] = useAtom( sessionTokenAtom )
    const [, setLoggedIn] = useAtom( loggedInAtom )
    const [profileReload, ] = useAtom( profileReloadAtom )
    const [, setVoteAccountStatus] = useAtom( voteAccountValidAtom )
    const [deviceContext,] = useAtom( deviceContextAtom )

    useEffect( () => {

        clog( 'Session token update:  >' + sessionToken + '<' );

        updateAxiosClient( sessionToken, setSessionToken, setLoggedIn );

        setUser( TO_USER_DEFAULT );
        setLoggedIn( Trilean.U );

        if ( isNotDefined( sessionToken ) || sessionToken === 'undefined') {

            setLoggedIn( Trilean.F );
            return;
        }

        clog( 'Fetching profile....' )

        setProcessing( plusOne )
        const abort = new AbortController();
        fetchUserProfile( abort ).then( mapToData )
                                 .then( cloak( setUser ) )
                                 .then( user => checkVotingAccount( abort, extractVoteAccountCypher( user, deviceContext ) ) )
                                 .then( compose( mapToData, valueOf( STATUS ), coalesceTrilean, setVoteAccountStatus ) )
                                 .then( () => setLoggedIn( Trilean.T ) )
                                 .catch( () => setLoggedIn( Trilean.F ) )
                                 .catch( () => setVoteAccountStatus( Trilean.U ) )
                                 .finally( () => setProcessing( minusOne ) );

        return () => abort.abort();

        // eslint-disable-next-line
    }, [sessionToken, profileReload] )


    useEffect(() => {
        clog( 'P>' + processing );

    }, [processing]);

    return <BrowserRouter>
        <Routes>
            <Route path={HOME_PAGE} element={<Navigate to={VOTES_PAGE}/>}/>
            <Route path={ERROR_PAGE} element={<ErrorPage/>}/>
            <Route path={PUBLIC_VOTE_PAGE} element={<Layout><PublicVotePage/></Layout>}/>
            <Route path={VOTES_PAGE} element={<Layout><Secure><Votes/></Secure></Layout>}/>
            <Route path={KYC_OPS_PAGE} element={<Layout><Secure><KycOperations/></Secure></Layout>}/>
            <Route path={PARTNERS_PAGE} element={<Layout><Secure><Partners/></Secure></Layout>}/>
            <Route path={HISTORY_PAGE} element={<Layout><Secure><History/></Secure></Layout>}/>
            <Route path={SETTINGS_PAGE} element={<Layout><Secure><Settings/></Secure></Layout>}/>
            <Route path={PARTNER_PAGE} element={<Layout><Secure><Partner/></Secure></Layout>}/>
            <Route path={MANAGE_VOTES_PAGE} element={<Layout><Secure><ManageVotes/></Secure></Layout>}/>
            <Route path={MANAGE_CONNECTIONS_PAGE} element={<Layout><Secure><ManageConnections/></Secure></Layout>}/>
            <Route path={CREATE_VOTE_FLOW_PAGE} element={<Layout><Secure><CreateVoteFlow/></Secure></Layout>}/>
            <Route path={PUBLISH_VOTE_FLOW_PAGE} element={<Layout><Secure><PublishVoteFlow/></Secure></Layout>}/>
            <Route path="*" element={<NotFound/>}/>
        </Routes>
    </BrowserRouter>
}

const root = ReactDOM.createRoot( document.getElementById( 'root' ) );
root.render( <App/> );

