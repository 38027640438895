import {pathIsNot, SETTINGS_PAGE} from "../fn/navigation-fn";
import {Link} from "react-router-dom";
import {processingAtom, voteAccountValidAtom} from "../fn/atomic";
import {useAtom} from "jotai";
import {isProcessing} from "../fn/fn";
import React from "react";

const NavTop = () => {

    const [processing,] = useAtom(processingAtom);
    const [voteAccountValid,] = useAtom(voteAccountValidAtom);

    const currentPathIsNot = pathIsNot(window.location.href);

    const linkColor = page => voteAccountValid.isFalse() ? "text-danger" : currentPathIsNot(page) ? "text-dark" : "text-primary";

    return (
        <nav className="navbar fixed-top shadow-sm bg-white">


            <div className="container p-0 my-2 d-flex justify-content-between align-items-center">

                <h4 className={"logo mb-0 " + (isProcessing(processing)? ' pulsate-icon ' : '')}>
                    <i className="bi bi-box "/></h4>
                <h5 className=" mb-0">BLOCVOTE</h5>

                <Link to={SETTINGS_PAGE} className={"h5 mb-0 " + (processing ? ' disabled-link ' : ' ')}>
                    <i className={"rounded-circle p-2 bg-light bi bi-gear " + linkColor(SETTINGS_PAGE)}/>
                </Link>

            </div>
        </nav>
    );
}

export default NavTop;
