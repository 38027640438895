import {DATA, PARTNER_PAGE, SESSION_HEADER} from "./dict";
import {clog, valueOfOr} from "puppit-shared/fn";
import axios from "axios";
import {Trilean} from "dahai-mvl/dist/main/ts/Trilean";


export const apiBaseUrl = process.env['REACT_APP_GATEWAY_API_URL'];

export const captchaKey = process.env['REACT_APP_CAPTCHA_KEY'];

export const apiUrlFor = url => apiBaseUrl + url;

export const mapToData = valueOfOr(DATA, []);

export var api = axios.create();

export const updateAxiosClient = (sessionToken, setSessionToken, setLoggedIn) => {

    axios.defaults.headers.common[SESSION_HEADER] = sessionToken;

    const instance = axios.create();

    instance.interceptors.response.use(response => response,
        error => {
            // Any status codes that falls outside the range of 2xx cause this function to trigger
            // Do something with response error
            if (error?.code === 'ERR_CANCELED')
                return Promise.resolve();

            clog("Axios error " + error?.response?.status)

            if (error?.response?.status === 401) {
                clog("Axios logged out " + error.response.status)
                setSessionToken(undefined);
                setLoggedIn(Trilean.F);
                return Promise.resolve();
            }

            return Promise.reject(error)
        });
    api = instance;
}

export const fetchUserProfile = abort => {
    const url = new URL(apiUrlFor('/profile'));
    return api.get(url.toString(), {signal: abort.signal});
}

export const updateUserProfile = userProfile => {
    let url = new URL(apiUrlFor('/profile'));
    return api.post(url.toString(), userProfile);
}


export const sendOtp = (email, deviceId, captcha) => {

    let url = new URL(apiUrlFor('/otp/send'));

    return api.post(url.toString(), {
        email: email.toLowerCase().trim(),
        deviceId: deviceId,
        captcha: captcha
    });
}

export const attemptLogin = (email, deviceId, otp, cypher) => {

    let url = new URL(apiUrlFor('/otp/login'));

    return api.post(url.toString(), {
        email: email.toLowerCase().trim(),
        deviceId: deviceId,
        cypher: cypher,
        otp: otp
    });
}

export const fetchVotes = abort => {

    let url = new URL(apiUrlFor('/vote/all'));

    return api.get(url.toString(), {signal: abort.signal});
}


export const fetchPublicVote = (abort, internalId) => {

    let url = new URL(apiUrlFor('/public/vote/' + internalId));
    return api.get(url.toString(), {signal: abort.signal});
}

export const fetchVote = (abort, internalId) => {

    let url = new URL(apiUrlFor('/vote/' + internalId));
    return api.get(url.toString(), {signal: abort.signal});
}

export const fetchVotingHistory = abort => {
    const url = apiUrlFor('/vote/history');
    return api.get(new URL(url).toString(), {signal: abort.signal})
}

export const castVote = (abort, internalId, voteOption, voteAccountCypher) => {
    const vo = {
        voteAccountCypher: voteAccountCypher,
        internalId: Number(internalId),
        voteOption: voteOption
    };

    const url = new URL(apiUrlFor('/vote/cast'));
    return api.post(url.toString(), vo, {signal: abort.signal});
}


export const cancelUpload = docId => {

    const loc = apiUrlFor('/upload-cancel');
    return api.post(new URL(loc).toString(),
        {documentId: docId});
}

export const submitKycVerification = (vo) => {

    const loc = apiUrlFor('/kyc/verification/submit');
    return api.post(new URL(loc).toString(), vo);
}

export const cancelKycVerification = () => {

    const loc = apiUrlFor('/kyc/verification/cancel');
    return api.post(new URL(loc).toString());
}

export const acceptKycVerifications = (abort, profileId, documentType) => {

    const loc = apiUrlFor('/kyc/verification/accept');
    return api.post(new URL(loc).toString(),
        {
            profileId: profileId,
            documentType: documentType,
            notify: true
        },
        {signal: abort.signal});
}

export const rejectKycVerifications = (abort, profileId, reason, notify ) => {

    const loc = apiUrlFor('/kyc/verification/reject');
    return api.post(new URL(loc).toString(),
        {
            profileId: profileId,
            reason: reason,
            notify: notify
        },
        {signal: abort.signal});
}

export const fetchKycVerifications = abort => {

    const loc = apiUrlFor('/kyc/verification/fetch');
    return api.get(new URL(loc).toString(), {signal: abort.signal});
}

export const fetchAvailablePartnersForPartner = abort => {
    const url = new URL(apiUrlFor('/partner/available/partner'));
    return api.get(url.toString(), {signal: abort.signal});
}

export const fetchAvailablePartnersForVoter = abort => {
    const url = new URL(apiUrlFor('/partner/available/voter'));
    return api.get(url.toString(), {signal: abort.signal});
}


export const fetchPartner = (abort, partnerId) => {
    let url = new URL(apiUrlFor('/partner/' + partnerId));
    return api.get(new URL(url).toString(), {signal: abort.signal})
}

export const connect = (partnerId, connected) => {
    const loc = apiUrlFor('/profile/connect');
    return api.post(new URL(loc).toString(), {
        partnerId: partnerId,
        connected: connected.toString()
    });
}

export const thePartnerPage = pid => PARTNER_PAGE.replaceAll(":partnerId", pid)


export const fetchPartnerVotes = abort => {
    const url = new URL(apiUrlFor('/partner/vote/all'));
    return api.get(url.toString(), {signal: abort.signal});
}

export const checkPartnerVote = data => {
    const url = new URL(apiUrlFor('/partner/vote/check'));
    return api.post(url.toString(), data);
}

export const publishPartnerVote = data => {
    const url = new URL(apiUrlFor('/partner/vote/publish'));
    return api.post(url.toString(), data);
}

export const updatePartnerVote = data => {
    const url = new URL(apiUrlFor('/partner/vote/update'));
    return api.post(url.toString(), data);
}

export const deleteDraftVote = data => {
    const url = new URL(apiUrlFor('/partner/vote/delete'));
    return api.post(url.toString(), data);
}

export const fetchPartnerZones = (abort, partnerId) => {
    const url = new URL(apiUrlFor('/partner/' + partnerId + '/zones'));
    return api.get(url.toString(), {signal: abort.signal});
}


export const exportPartnerConnections = (abort) => {
    const url = new URL(apiUrlFor('/partner/connections/export'));
    return api.get(url.toString(), {signal: abort.signal});
}

export const fetchPartnerConnections = (abort) => {
    const url = new URL(apiUrlFor('/partner/connections'));
    return api.get(url.toString(), {signal: abort.signal});
}

export const postSecurityAudit = action => {

    let url = new URL(apiUrlFor('/security/audit'));
    return api.post(url.toString(), {
        securityAuditAction: action
    });
};

export const fetchSecurityAudit = abort => {

    let url = new URL(apiUrlFor('/security/audit'));
    return api.get(url.toString(), {signal: abort.signal});
};

export const checkVotingAccount = (abort, cypher) => {

    let url = new URL(apiUrlFor('/security/check'));

    const vo = {voteAccountCypher: cypher}

    return api.post(url.toString(), vo, {signal: abort.signal});
};

export const resetAccount = abort => {
    let url = new URL(apiUrlFor("/security/reset-account"))
    return api.post(url.toString(), {signal: abort.signal})
}
