import {fetchVotingHistory, mapToData} from "../fn/api";
import React, {useEffect, useState} from "react";
import {HAS_VOTED, VOTE_CLOSE_TIMESTAMP} from "../fn/dict";
import {doNothing, is, isEmpty, isNotDefined} from "puppit-shared/fn";
import {processingAtom} from "../fn/atomic";
import {descending, minusOne, plusOne, toKeyedDiv} from "../fn/fn";
import {useAtom} from "jotai";
import PageHeading from "./PageHeading";
import VoteCard from "./vote/VoteCard";

const History = () => {

    const [, setProcessing] = useAtom(processingAtom)
    const [votingHistory, setVotingHistory] = useState(undefined)

    useEffect(() => {

        setProcessing(plusOne);
        const abort = new AbortController();
        fetchVotingHistory(abort).then(mapToData)
            .then(setVotingHistory)
            .finally(() => setProcessing(minusOne));

        return () => abort.abort();

        // eslint-disable-next-line
    }, [])

    if (isNotDefined(votingHistory))
        return <></>

    let lead = 'All the issues you have voted on.';
    if (isEmpty(votingHistory))
        lead = 'You have not voted on any issues yet, to get started complete and verify your account details.'


    return <>
        <PageHeading icon={<i className='rounded-circle p-2 bg-light bi bi-clock-history text-primary'/>}
                     heading='Voting history'
                     lead={lead}/>

        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-3">
            {votingHistory.filter(is(HAS_VOTED, true))
                .sort(descending(VOTE_CLOSE_TIMESTAMP))
                .map(vo => <VoteCard data={vo} onAfterVote={doNothing}/>)
                .map(toKeyedDiv("col mb-3", "vote"))}
        </div>
    </>
};

export default History;
