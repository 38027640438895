import * as countries from "i18n-iso-countries";
import {isNotDefined, valueOf, valueOfOr} from "puppit-shared/fn";

export const GBR_SCT = "GBR-SCT";
export const GBR_NIR = "GBR-NIR";
export const GBR_WLS = "GBR-WLS";
export const en = "en";
export const GBR = "GBR";
export const AUS = "AUS";
export const USA = "USA";

countries.registerLocale( require( "i18n-iso-countries/langs/en.json" ) );

export const getCountryName = code => {

    switch ( code ) {
        case GBR_SCT:
            return "Scotland";
        case GBR_NIR:
            return "Northern Ireland";
        case GBR_WLS:
            return "Wales";
        default:
            return countries.getName( code, en );
    }
}

export const preferredCodes = [GBR, GBR_SCT, GBR_NIR, GBR_WLS, AUS, USA];

export const theRemainderCodes = Object.keys( countries.getAlpha3Codes() )
                                        .filter( c => preferredCodes.indexOf( c ) === -1 );


export const allCountryCodes = preferredCodes.concat( theRemainderCodes );

const dict = {
    'postcode': { GBR: 'Postcode', GBR_ENG: 'Postcode', USA: 'Zipcode' }
}

export const translatorFor = locale => word => {

    const translations = valueOfOr( word, undefined )( dict )

    if ( isNotDefined( translations ) )
        return word;

    let translated = valueOf( locale )( translations )

    if ( isNotDefined( translated ) )
        translated = valueOf( GBR )( translations )

    return translated;
}


