import React, {useEffect, useState} from "react";
import {isEmpty, isNotDefined, valueOf, valueOfOr} from "puppit-shared/fn";
import {
    ISSUE_IMAGE,
    ISSUE_LINK,
    ISSUE_QUESTION,
    ISSUE_SUMMARY,
    PARTNER_NAME,
    VOTE_CLOSE_TIMESTAMP,
    ZONE_NAME
} from "../../fn/dict";
import {useAtom} from "jotai/index";
import {loggedInAtom, processingAtom, userAtom} from "../../fn/atomic";
import {differenceInDays, minusOne, plusOne, textToParagraph} from "../../fn/fn";
import {fetchPublicVote, fetchVote, mapToData} from "../../fn/api";
import {useParams} from "react-router-dom";
import HaveYourSayPanel from "./HaveYourSayPanel";
import LoginModal from "../login/LoginModal";
import {isClosingSoon} from "../../fn/vote-fn";
import ShareThisVotePanel from "./ShareThisVotePanel";
import {blocvoteExplainerVideoUrl, directDemocracyRightNowLink} from "../../fn/navigation-fn";
import Metadata from "../Metadata";

const PublicVotePage = () => {

    const {internalId} = useParams();
    const [isLoggedIn,] = useAtom(loggedInAtom);
    const [user,] = useAtom(userAtom);
    const [, setProcessing] = useAtom(processingAtom);

    const [showLoginInModal, setShowLoginModal] = useState(false);
    const [vote, setVote] = useState(undefined);
    const [reload, setReload] = useState(0);

    const partnerName = valueOf(PARTNER_NAME)(vote);
    const zoneName = valueOf(ZONE_NAME)(vote);
    const issueQuestion = valueOf(ISSUE_QUESTION)(vote);
    const issueSummary = valueOf(ISSUE_SUMMARY)(vote);
    const issueLink = valueOf(ISSUE_LINK)(vote);
    const issueImage = valueOf(ISSUE_IMAGE)(vote);

    useEffect(() => {

        const abort = new AbortController();

        if (isLoggedIn.isUnknown())
            return;

        setProcessing(plusOne);

        const loader = isLoggedIn.isTrue() ? fetchVote(abort, internalId) : fetchPublicVote(abort, internalId);

        loader.then(mapToData)
            .then(setVote)
            .finally(() => setProcessing(minusOne));

        return () => abort.abort();

    }, [reload, isLoggedIn, setProcessing, internalId, user])

    useEffect(() => {
        if (isLoggedIn.isTrue())
            setShowLoginModal(false);
    }, [isLoggedIn]);

    const voteExpires = valueOfOr(VOTE_CLOSE_TIMESTAMP, undefined)(vote);
    const closeDate = new Date(voteExpires);
    const difference = closeDate.getTime() - new Date();

    if (isNotDefined(vote) || isEmpty(vote))
        return <></>

    return <>
        <Metadata
            title={'BLOCVOTE: ' + issueQuestion }
            description={issueSummary}
            url={window.location.href}
            image={issueImage}
        />
        <LoginModal isShowing={showLoginInModal} onHide={() => setShowLoginModal(false)}/>

        <div className="card border-0">

            <div className="card-body">
                <h2 className="card-title mb-1"><b>{issueQuestion}</b></h2>
                <div className="card-text text-muted">{partnerName}, {zoneName}</div>
            </div>
            <div className="card-body">

                <div className="row">
                    <div className="col-md-7 mb-5">
                        <img alt='' hidden={isNotDefined(issueImage)} className='mb-3 img-fluid' src={issueImage}/>

                        <h4><b>Why this issue matters</b></h4>
                        <div>{textToParagraph(issueSummary)}</div>
                        <div hidden={isNotDefined(issueLink)}><a target='_new' href={issueLink}>More
                            information &nbsp;&nbsp;<i className="bi bi-box-arrow-up-right"/></a>
                        </div>
                    </div>
                    <div className="col-md-5">
                        <div className='mb-5'>
                            <HaveYourSayPanel vote={vote}
                                              closingSoon={isClosingSoon(differenceInDays(difference))}
                                              onAfterVote={()=>setReload(plusOne)}
                                              onLogin={() => setShowLoginModal(true)}/>
                        </div>
                        <div className='mb-5'>

                            <div>
                                <h4 className='mb-1'><b>About us</b></h4>
                                <div className="pb-3">Watch this short animation to discover our mission, and
                                    dont forget to visit our friends at <a href={directDemocracyRightNowLink()}>
                                        DDRightNow&nbsp;&nbsp;<i className="bi bi-box-arrow-up-right"/>
                                    </a></div>
                            </div>

                            <div className="embed-responsive embed-responsive-16by9">
                                <video className="embed-responsive-item" controls={true}>
                                    <source src={blocvoteExplainerVideoUrl()} type="video/mp4"/>
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                        </div>
                        <div className='mb-5'>
                            <ShareThisVotePanel vote={vote}/>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </>
}

export default PublicVotePage
