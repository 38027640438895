import React from "react";
import {ISSUE_QUESTION, VOTE} from "../../fn/dict";
import {valueOf} from "puppit-shared/fn";
import {
    FacebookIcon,
    FacebookShareButton,
    TelegramIcon,
    TelegramShareButton,
    TwitterShareButton,
    WhatsappIcon,
    WhatsappShareButton,
    XIcon
} from "react-share";
import QRCode from "qrcode.react";

const ShareThisVotePanel = params => {

    const vote = valueOf(VOTE)(params);
    const title = valueOf( ISSUE_QUESTION )( vote );
    const shareUrl = document.URL;

    return (
        <div>
            <div>
                <h4 className='mb-1'><b>Share this vote</b></h4>
                <div className="pb-3">Help us reach more people on this important issue</div>
            </div>

            <div className='d-flex justify-content-start gap-3 mb-4'>


                <TwitterShareButton className='mr-3' url={shareUrl} title={title}>
                    <XIcon size={32}/>
                </TwitterShareButton>

                <FacebookShareButton className='mr-3' url={shareUrl} title={title}>
                    <FacebookIcon size={32}/>
                </FacebookShareButton>

                <WhatsappShareButton className='mr-3' url={shareUrl} title={title}>
                    <WhatsappIcon size={32}/>
                </WhatsappShareButton>

                <TelegramShareButton className='mr-3' url={shareUrl} title={title}>
                    <TelegramIcon size={32}/>
                </TelegramShareButton>

            </div>
            <QRCode value={shareUrl} size={128} level={"H"}/>

        </div>
    )
}
export default ShareThisVotePanel
