import OtpInput from 'react-otp-input';
import {useState} from "react";
import {useAtom} from "jotai";
import {deviceIdAtom, processingAtom, sessionTokenAtom, userAtom} from "../../fn/atomic";
import {IS_MODAL, SESSION_TOKEN, SUCCESS} from "../../fn/dict";
import {clog, coalesce, doNothing, valueOf, valueOfOr} from "puppit-shared/fn";
import {isProcessing, minusOne, plusOne} from "../../fn/fn";
import {attemptLogin, mapToData} from "../../fn/api";
import {headShake} from 'react-animations';
import {css, StyleSheet} from 'aphrodite';


export const onError = (errorCode, doThis, doThat) => error => error?.response?.status === errorCode ? doThis() : doThat();

const Otp = props => {

    const goBack = coalesce(props.back, doNothing);

    const OTP_LENGTH = 4;

    const [user,] = useAtom(userAtom);
    const [otp, setOtp] = useState('');
    const [deviceId,] = useAtom(deviceIdAtom);
    const [, setSessionToken] = useAtom(sessionTokenAtom);
    const [processing, setProcessing] = useAtom(processingAtom);
    const [incorrect, setIncorrect] = useState(false);

    const isModal = valueOfOr(IS_MODAL, false)(props);

    const styles = StyleSheet.create({
        none: {},
        headShake: {
            animationName: headShake,
            animationDuration: '3s'
        }
    });

    const headShakeStyle = (incorrect ? css(styles.headShake) : css(styles.none));

    const login = vo => {
        setIncorrect(false);
        setSessionToken(valueOf(SESSION_TOKEN)(vo));
    }

    const codeNotEntered = () => otp.length !== OTP_LENGTH;

    const handleClick = () => {

        setProcessing(plusOne);
        setIncorrect(false);

        const emailLower = user.email.toLowerCase().trim();

        attemptLogin(emailLower, deviceId, otp).then(mapToData)
            .then(vo => {
                if (vo[SUCCESS] === true) login(vo);
                else {
                    setIncorrect(true)
                    setTimeout(() => setIncorrect(false), 3000);
                }
            })
            .catch(onError(403, goBack, clog))
            .finally(() => {
                setOtp('');
                setProcessing(minusOne);
            });
    }

    return (
        <div className={isModal ? ' ' : ' pt-3 mt-3 '}>
            <div className=" d-flex align-items-center justify-content-center">

                <div className="minw-70 col-sm-6 col-lg-4">

                    <h1 className="display-1 logo text-center"><i className="bi bi-box"/></h1>
                    <h5 className="card-title h3 text-center mb-3">Enter Code</h5>
                    <p className="text-muted mb-3 text-center">We've sent a code to your email address, if it has not
                        arrived, check your spam or click back and try again.</p>

                    <div className={headShakeStyle}>

                        <OtpInput
                            value={otp}
                            shouldAutoFocus={true}
                            containerStyle="d-flex justify-content-center py-4 "
                            inputStyle={"no-highlight no-cursor border border-3 rounded text-dark h1 otp-w mx-2" + (incorrect ? ' border-danger ' : ' border-dark ')}
                            onChange={setOtp}
                            numInputs={OTP_LENGTH}
                            inputType='tel'
                            renderInput={(props) => {
                                props.disabled = isProcessing(processing)
                                return <input {...props} />
                            }}
                        />
                    </div>

                    <div className="d-flex justify-content-between gap-2 g-4 mt-4">

                        <button type="button"
                                onClick={goBack}
                                disabled={isProcessing(processing)}
                                className="btn btn-outline-dark mr-4 ">
                            Back
                        </button>

                        <button type="button"
                                onClick={handleClick}
                                disabled={isProcessing(processing) || codeNotEntered()}
                                className="btn btn-outline-dark">
                            Continue
                        </button>

                    </div>
                </div>
            </div>
        </div>);
};

export default Otp;
