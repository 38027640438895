import React from "react";
import { valueOf } from "puppit-shared/fn";
import { ON_HIDE } from "../../fn/dict";
import PageHeading from "../PageHeading";
import { supportIcon } from "../../fn/icons";
import { helpVideoLink, whatsAppLink, xLink } from "../../fn/fn";


const AccountDetails = props => {

    const hide = valueOf( ON_HIDE )( props );

    return (
        <>
            <PageHeading heading="Support"
                         lead={"Welcome to the community! We are all ready to support you and help you get the best experience. You can reach out to us at hello@blocvote.org or join the community groups below"}
                         icon={supportIcon( ' text-success ' )}/>

            <a href={whatsAppLink()} target='_support' className='btn btn-lg btn-primary btn-block'>
                <i className="bi bi-whatsapp "/>&nbsp;&nbsp;Join us on chat
            </a>

            <a href={xLink()} target='_support' className='btn btn-lg btn-primary btn-block mt-4 '>
                <i className="bi bi-twitter-x "/>&nbsp;&nbsp;Follow us
            </a>

            <a href={helpVideoLink()} target='_support' className='btn btn-lg btn-primary btn-block mt-4 '>
                <i className="bi bi-camera-reels "/>&nbsp;&nbsp;Watch the how to videos
            </a>

            <div className="d-flex justify-content-end mt-4">

                <button
                    onClick={hide}
                    type="button"
                    className="btn btn-outline-dark rounded">
                    Close
                </button>

            </div>

        </>
    )
        ;
}

export default AccountDetails