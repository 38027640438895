import React from "react";
import { trileanWhen } from "./tnew";

export const circleIcon = clazz => <i className={"rounded-circle p-2 bg-light bi bi-circle-fill  " + clazz}/>;
export const accountIcon = clazz => <i className={"rounded-circle p-2 bg-light bi bi-person-circle  " + clazz}/>;
export const infoIcon = clazz => <i className={"rounded-circle p-2 bg-light bi bi-info-circle  " + clazz}/>;

export const voteIcon = clazz => <i className={"rounded-circle p-2 bg-light bi bi-box  " + clazz}/>;
export const supportIcon = clazz => <i className={"rounded-circle p-2 bg-light bi bi-question-circle-fill  " + clazz}/>;
export const securityIcon = clazz => state => <i className={"rounded-circle p-2 bg-light bi " +
    trileanWhen(' bi-shield-check text-primary ',' bi-shield-x text-info ',' bi-shield-exclamation text-danger ')(state) +
    clazz}/>;

export const checkIcon = clazz => state => <i className={"rounded-circle p-2 bg-light bi " +
    trileanWhen(' bi bi-check-circle-fill text-success ',' bi bi-circle-fill text-warning ',' bi bi-circle-fill text-danger ')(state) +
    clazz}/>;