import React, { useState } from "react";
import { doNothing, isBlank, isNotBlank, valueOf, valueOfOr } from "puppit-shared/fn";
import { useAtom } from "jotai";
import { EMAIL } from "puppit-shared/dict";
import { ON_HIDE, STATUS, } from "../../fn/dict";
import { isProcessing, minusOne, plusOne } from "../../fn/fn";
import { checkVotingAccount, mapToData, postSecurityAudit, resetAccount } from "../../fn/api";
import { coalesceTrilean } from "../../fn/tnew";
import { updateUserContext } from "../../fn/security-fn";
import { deviceContextAtom, processingAtom, userAtom, voteAccountValidAtom } from "../../fn/atomic";
import PageHeading from "../PageHeading";
import { securityIcon } from "../../fn/icons";
import { Trilean } from "dahai-mvl/dist/main/ts/Trilean";

export const RESET = 'reset';
export const ACTIVATE = 'activate';

const ActivationCodeUpdate = props => {

    const hide = valueOfOr( ON_HIDE, doNothing )( props );

    const defaultMessage = <div>Your activation code can be found in the settings menu on an authorised device</div>

    const [voteAccountCypher, setVoteAccountCypher] = useState( "" );
    const [processing, setProcessing] = useAtom( processingAtom );
    const [message, setMessage] = useState( defaultMessage );
    const [user,] = useAtom( userAtom );
    const [blocvoteUserContext, setBlocvoteUserContext] = useAtom( deviceContextAtom );
    const [, setVoteAccountValid] = useAtom( voteAccountValidAtom )
    const [errorMessage, setErrorMessage] = useState( undefined );
    const [action, setAction] = useState( undefined );
    const userEmail = valueOf( EMAIL )( user );
    const userContext = () => valueOf( userEmail )( blocvoteUserContext );

    const canNotActivate = () => isProcessing( processing ) || isBlank( voteAccountCypher );

    const resetting = () => action === RESET;
    const activating = () => action === ACTIVATE;

    const updateMnemonic = e => {

        const value = ( e.target.value || '' );
        setVoteAccountCypher( value );
        setMessage( defaultMessage );
    }

    const handleReset = () => {

        setProcessing( plusOne );
        setAction( RESET );
        setMessage( defaultMessage );

        const abort = new AbortController();
        resetAccount( abort ).then( mapToData )
                             .then()
                             .then( vo => {
                                 updateUserContext( userEmail, userContext(), setBlocvoteUserContext )( vo )
                                 setErrorMessage( undefined );
                                 setVoteAccountValid( Trilean.T );
                                 hide();
                             } )
                             .then( () => postSecurityAudit( "Account reset" ) )
                             .catch( e => setErrorMessage( "Oops, something went wrong on our side, please contact support." ) )
                             .finally( () => {
                                 setProcessing( minusOne );
                                 setAction( undefined );
                             } )
        return () => abort.abort();


    }

    const handleActivate = () => {

        const account = { voteAccountCypher: voteAccountCypher }

        setProcessing( plusOne );
        setAction( ACTIVATE );
        setMessage( defaultMessage );

        const abort = new AbortController();
        checkVotingAccount( abort, voteAccountCypher )
            .then( mapToData )
            .then( resp => {
                const accountIsValid = coalesceTrilean( valueOf( STATUS )( resp ) ).isTrue()

                if ( accountIsValid ) {
                    updateUserContext( userEmail, userContext(), setBlocvoteUserContext )( account )
                    setErrorMessage( undefined );
                    setVoteAccountValid( Trilean.T );
                    hide();
                    return postSecurityAudit( "Device activated" ).then();
                } else
                    setErrorMessage( "The activation code is incorrect." )
            } )
            .catch( e => setErrorMessage( "Oops, something went wrong on our side, please contact support." ) )
            .finally( () => {
                setProcessing( minusOne );
                setAction( undefined );
            } )

        return () => abort.abort();
    }

    return <>
        <PageHeading icon={securityIcon()( Trilean.F )}
                     heading='Device Activation'
                     lead='Your activation code has not been found on this device.  Please enter it and click activate.
                           If you no longer have access to the code you may reset your account.'/>

        <div className="input-group">
                    <textarea disabled={isProcessing( processing )}
                              onChange={updateMnemonic} value={voteAccountCypher} rows="6"
                              aria-describedby="mnemonicHelp"
                              className="form-control w-100"/>
            <div hidden={isNotBlank( errorMessage )} id="mnemonicHelp" className="text-muted mt-1">{message}</div>
        </div>
        <div className=" form-text text-danger">{errorMessage}</div>
        <div className="d-flex justify-content-between mt-4 mb-3">
            <button onClick={hide}
                    disabled={isProcessing( processing )}
                    className="btn btn-outline-dark ">Close
            </button>
            <div>
                <button onClick={handleReset}
                        disabled={isProcessing( processing )}
                        className="btn btn-outline-danger mr-4">
                        <span hidden={!resetting()}>
                        <span className="spinner-grow spinner-grow-sm"/>&nbsp;&nbsp;</span>Reset
                </button>
                <button onClick={handleActivate}
                        disabled={isProcessing( processing ) || canNotActivate()}
                        className="btn btn-outline-success">
                    <span hidden={!activating()}>
                        <span className="spinner-grow spinner-grow-sm"/>&nbsp;&nbsp;</span>Activate
                </button>
            </div>
        </div>
    </>
}

export default ActivationCodeUpdate;
