import React, {useState} from "react";
import Otp from "./Otp";
import Login from "./Login";
import {valueOfOr} from "puppit-shared/fn";
import {IS_MODAL} from "../../fn/dict";

const LoginFlow = params => {

    const STAGE_LOGIN = 'login';
    const STAGE_OTP = 'otp';
    const isModal = valueOfOr( IS_MODAL, false)( params )

    const [stage, setStage] = useState( STAGE_LOGIN );

    const next = () => setStage( STAGE_OTP );
    const back = () => setStage( STAGE_LOGIN );

    return stage === STAGE_LOGIN ? <Login next={next} isModal={isModal}/> : <Otp back={back} isModal={isModal}/>
};

export default LoginFlow;
