import React, {useState} from "react";
import {doNothing, isDefined, valueOf, valueOfOr} from "puppit-shared/fn";
import {deviceContextAtom, processingAtom, userAtom, voteAccountValidAtom} from "../../fn/atomic";
import {useAtom} from "jotai";
import {AGAINST, IN_FAVOUR, INTERNAL_ID, ON_AFTER_VOTE, VOTE} from "../../fn/dict";
import {castVote, mapToData} from "../../fn/api";
import {getterFor, isProcessing, minusOne, plusOne} from "../../fn/fn";
import {Navigate} from "react-router-dom";
import {ERROR_PAGE} from "../../fn/navigation-fn";
import SecurityProblemModal from "../SecurityProblemModal";
import {extractVoteAccountCypher} from "../../fn/user";

const VotePanel = params => {

    const onAfterVote = valueOfOr( ON_AFTER_VOTE, doNothing )( params );

    const [user,] = useAtom( userAtom );
    const [deviceContext,] = useAtom( deviceContextAtom );
    const [redirect, setRedirect] = useState( undefined );
    const [voteAccountStatus,] = useAtom( voteAccountValidAtom );
    const [securityModalIsOpen, setSecurityModalIsOpen] = useState( false )
    const [processing, setProcessing] = useAtom( processingAtom );
    const [decision, setDecision] = useState( undefined );

    const voteAccountCypher = extractVoteAccountCypher( user, deviceContext );

    const getter = getterFor( valueOf( VOTE )( params ) );

    const closeSecurityModal = () => setSecurityModalIsOpen( false )
    const openSecurityModal = () => setSecurityModalIsOpen( true )

    const doVote = decision => {

        setProcessing( plusOne );
        setDecision( decision )
        const abort = new AbortController();
        castVote( abort, getter( INTERNAL_ID ), decision, voteAccountCypher )
            .then( mapToData )
            .then( onAfterVote )
            .catch( () => setRedirect( ERROR_PAGE ) )
            .finally( () => {
                setProcessing( minusOne );
                setDecision( undefined )
            } )
    }


    const doVoteIfNecessary = decision => () => {

        if ( voteAccountStatus.isNotTrue() )
            openSecurityModal();
        else
            doVote( decision );
    }


    if ( isDefined( redirect ) )
        return <Navigate to={redirect}/>

    return (
        <div className="border-0">
            <SecurityProblemModal isOpen={securityModalIsOpen} close={closeSecurityModal}/>

            <button className="btn rounded btn-block btn-primary mb-4"
                    disabled={isProcessing( processing )}
                    onClick={doVoteIfNecessary( IN_FAVOUR )}>
                <span hidden={decision !== IN_FAVOUR}>
                        <span className="spinner-grow spinner-grow-sm"/>&nbsp;&nbsp;</span>
                Yes
            </button>

            <button className="btn rounded btn-block btn-info"
                    disabled={isProcessing( processing )}
                    onClick={doVoteIfNecessary( AGAINST )}>
                <span hidden={decision !== AGAINST }>
                        <span className="spinner-grow spinner-grow-sm"/>&nbsp;&nbsp;</span>No
            </button>
        </div>
    );

}
export default VotePanel
