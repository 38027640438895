import React, { useState } from "react";
import { useAtom } from "jotai";
import { deviceContextAtom, userAtom } from "../../fn/atomic";
import { extractVoteAccountCypher } from "../../fn/user";
import PageHeading from "../PageHeading";
import { securityIcon } from "../../fn/icons";
import { Trilean } from "dahai-mvl/dist/main/ts/Trilean";
import { valueOf } from "puppit-shared/fn";
import { ON_HIDE } from "../../fn/dict";

const ManageAccountActivationCode = props => {

    const [user,] = useAtom( userAtom );
    const [deviceContext,] = useAtom( deviceContextAtom );
    const [showing, setShowing] = useState( false );
    const [copied, setCopied] = useState( false );

    const hide = valueOf( ON_HIDE )( props )

    const handleCopied = () => {
        setCopied( true );
        setTimeout( () => setCopied( false ), 250 )
    }

    const accountCypher = extractVoteAccountCypher( user, deviceContext )

    const copyToClipboard = () => navigator.clipboard.writeText( accountCypher ).then( handleCopied );

    const showHide = () => {
        setShowing( showing => !showing );
        setTimeout( () => setShowing( false ), 60000 )
    }

    return <>

        <PageHeading icon={securityIcon()( Trilean.T )}
                     heading='Security'
                     lead='This is your account activation code, please keep a copy safely as it can not be recovered if lost.'/>

        <div className='text-wrap mb-3'>
            <pre><code className={'text-wrap ' + ( showing ? ' ' : ' blurry-text ' ) + (copied || showing ? ' text-success ' : '') }>{accountCypher}</code></pre>
        </div>

        <div className="d-flex justify-content-between gap-4 mt-4">

            <button onClick={hide} className="mr-3 btn btn-outline-dark ">Close</button>

            <div>
                <button onClick={copyToClipboard} className="mr-3 btn btn-outline-success ">Copy</button>
                <button onClick={showHide} className="btn btn-outline-success ">{( showing ? 'Hide' : 'Show' )}</button>
            </div>
        </div>

    </>
}

export default ManageAccountActivationCode;
