import React, {useEffect, useState} from "react";
import {Dropdown} from "react-bootstrap";
import {useAtom} from "jotai";
import {userAtom} from "../fn/atomic";
import {acceptKycVerifications, fetchKycVerifications, mapToData, rejectKycVerifications} from "../fn/api";
import {getterFor} from "../fn/fn";
import {ADMIN, COUNTRY_CODE, MIME_TYPE, PROFILE_ID, ZIP_CODE} from "../fn/dict";
import {DATA, NAME} from "puppit-shared/dict";
import {getCountryName} from "../fn/locale";
import {isNot} from "puppit-shared/fn";
import {Document, Page, pdfjs} from "react-pdf";
import {hasRole} from "../fn/user";

const KycOperations = () => {

    const INCORRECT = "incorrect";
    const INCOMPLETE = "incomplete";
    const UNACCEPTABLE = "unacceptable";
    const SUSPICIOUS = "suspicious";
    const UNCLEAR = "unclear";

    const PASSPORT = "passport";
    const DRIVING_LICENCE = "drivingLicence";
    const UTILITY_BILL = "utilityBill";
    const BANK_STATEMENT = "bankStatement";
    const OTHER = "other";

    const [processing, setProcessing] = useState(false);
    const [userProfile,] = useAtom(userAtom);

    const [requests, setRequests] = useState([]);

    useEffect(() => {
        pdfjs.GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/' + pdfjs.version + '/pdf.worker.js';
    });

    const accept = (documentType, profileId) => () => {

        setProcessing(true);
        const abort = new AbortController();
        acceptKycVerifications(abort, profileId, documentType)
            .then(() => setRequests(r => r.filter(isNot(PROFILE_ID, profileId))))
            .finally(() => setProcessing(false))
        return () => abort.abort();
    }

    const reject = (reason, profileId, notify) => () => {

        setProcessing(true);
        const abort = new AbortController();
        rejectKycVerifications(abort, profileId, reason, notify)
            .then(() => setRequests(r => r.filter(isNot(PROFILE_ID, profileId))))
            .finally(() => setProcessing(false))

        return () => abort.abort();
    }

    useEffect(() => {

        if (!hasRole(userProfile, ADMIN))
            return;

        const abort = new AbortController();
        fetchKycVerifications(abort).then(mapToData)
            .then(setRequests);

        return () => abort.abort();
        ;

    }, [userProfile])

    const rowFactory = (vo, idx) => {

        const get = getterFor(vo);

        const url = "data:" + get(MIME_TYPE) + ";base64," + get(DATA);
        const file = {
            url: url,
            withCredentials: false
        };

        const preview =
            (get(MIME_TYPE) === "application/pdf")
                ?
                <Document renderAnnotationLayer={false} file={file}><Page renderAnnotationLayer={false} pageNumber={1}/></Document>
                : <img src={url} className="card-img-top " alt="..."/>


        return <div key={idx} className="col">
            <div className="card">

                {preview}

                <div className="card-body">
                    <h5 className="card-title">{get(NAME)}</h5>
                    <p className="card-text">{getCountryName(get(COUNTRY_CODE)) + ', ' + get(ZIP_CODE)}</p>
                    <div className="card-text d-flex justify-content-between">

                        <Dropdown>
                            <Dropdown.Toggle disabled={processing} variant="outline-dark" id="dropdown-basic">
                                Reject
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item disabled={processing} onClick={reject(INCORRECT, get(PROFILE_ID), true)}>Details do not match</Dropdown.Item>
                                <Dropdown.Item disabled={processing} onClick={reject(INCOMPLETE, get(PROFILE_ID), true)}>Details incomplete</Dropdown.Item>
                                <Dropdown.Item disabled={processing} onClick={reject(UNCLEAR, get(PROFILE_ID), true)}>Details unclear</Dropdown.Item>
                                <Dropdown.Item disabled={processing} onClick={reject(UNACCEPTABLE, get(PROFILE_ID), true)}>Document not acceptable</Dropdown.Item>
                                <Dropdown.Item disabled={processing} onClick={reject(SUSPICIOUS, get(PROFILE_ID), false)}>Suspicious</Dropdown.Item>
                            </Dropdown.Menu>

                        </Dropdown>

                        <Dropdown>
                            <Dropdown.Toggle disabled={processing} variant="outline-success" id="dropdown-basic">
                                Accept
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item disabled={processing}
                                               onClick={accept(PASSPORT, get(PROFILE_ID))}>Passport</Dropdown.Item>
                                <Dropdown.Item disabled={processing}
                                               onClick={accept(DRIVING_LICENCE, get(PROFILE_ID))}>Driving
                                    License</Dropdown.Item>
                                <Dropdown.Item disabled={processing}
                                               onClick={accept(UTILITY_BILL, get(PROFILE_ID))}>Utility
                                    Bill</Dropdown.Item>
                                <Dropdown.Item disabled={processing}
                                               onClick={accept(BANK_STATEMENT, get(PROFILE_ID))}>Bank
                                    Statement</Dropdown.Item>
                                <Dropdown.Item disabled={processing}
                                               onClick={accept(OTHER, get(PROFILE_ID))}>Other</Dropdown.Item>
                            </Dropdown.Menu>

                        </Dropdown>
                    </div>
                </div>
            </div>
        </div>
    }


    return (<ul className="list-group list-group-flush">

            <li className="list-group-item"><h4>KYC</h4></li>

            <li className="list-group-item pt-3">

                <div className="row row-cols-xs-1 row-cols-lg-2 g-3">
                    {requests.map(rowFactory)}
                </div>
            </li>
        </ul>
    )
}

export default KycOperations
