import React, { useState } from "react";
import { doNothing, valueOf, valueOfOr } from "puppit-shared/fn";
import { EMAIL, NAME } from "puppit-shared/dict";
import { useAtom } from "jotai";
import { Trilean } from "dahai-mvl/dist/main/ts/Trilean";
import { processingAtom, userAtom } from "../../fn/atomic";
import { COUNTRY_CODE, ON_HIDE, PROFILE_VERIFIED, ZIP_CODE } from "../../fn/dict";
import { GBR, translatorFor } from "../../fn/locale";
import { getterFor, isNotProcessing, isProcessing, minusOne, plusOne, setterFor } from "../../fn/fn";
import { cancelKycVerification } from "../../fn/api";
import PageHeading from "../PageHeading";
import { accountIcon } from "../../fn/icons";


const AccountDetails = props => {

    const hide = valueOf( ON_HIDE )( props );

    const [processing, setProcessing] = useAtom( processingAtom );
    const [user, setUser] = useAtom( userAtom );
    const [countryCode,] = useState( valueOfOr( COUNTRY_CODE, GBR )( user ) );

    const withProfileVerified = setterFor( PROFILE_VERIFIED );
    const profile = getterFor( user );

    const translate = translatorFor( countryCode );

    const handleUnlock = () => {
        setProcessing( plusOne );
        cancelKycVerification().then( () => setUser( withProfileVerified( Trilean.F ) ) )
                               .then( hide )
                               .finally( () => setProcessing( minusOne ) );
    }

    return (
        <>
            <PageHeading heading="Account Details"
                         lead={"We respect your privacy and only keep the minimum amount of information possible.  We do not track your usage of this platform over time. "}
                         icon={accountIcon()}/>

            <form onSubmit={doNothing}>

                <div className="mb-3">

                    <label htmlFor="email" className="form-label">Email</label>
                    <input disabled={true}
                           type="email" className="form-control"
                           id="email"
                           value={profile( EMAIL )}/>
                </div>


                <div className="mb-3">

                    <label htmlFor="name" className="form-label">Name</label>
                    <input disabled={true}
                           type="text"
                           className="form-control"
                           id="name"
                           defaultValue={profile( NAME )}
                    />
                </div>

                <div className="mb-3">
                    <div>
                        <label htmlFor="country" className="form-label">Country</label>
                    </div>

                    <input disabled={true}
                           type="text"
                           className="form-control"
                           id="country"
                           defaultValue={translate( profile( COUNTRY_CODE ) )}
                    />
                </div>

                <div>
                    <label htmlFor="zipcode" className="form-label">{translate( 'postcode' )}</label>
                    <input disabled={true}
                           type="text"
                           className="form-control"
                           id="zipcode"
                           value={( profile( ZIP_CODE ) || '' ).toUpperCase()}/>
                </div>

                <div className="d-flex justify-content-between mt-4">

                    <button
                        disabled={isProcessing( processing )}
                        onClick={hide}
                        type="button"
                        className="btn btn-outline-dark  rounded">
                        Close
                    </button>

                    <button
                        disabled={isProcessing( processing )}
                        onClick={handleUnlock}
                        type="button"
                        className="btn btn-danger rounded">
                        <span hidden={isNotProcessing( processing )}>
                         <span className="spinner-grow spinner-grow-sm"/>&nbsp;&nbsp;</span>Unlock
                    </button>

                </div>
            </form>

        </>
    );
}

export default AccountDetails