import {useParams} from "react-router-dom";
import {cloak, coalesce, isNotDefined, valueOf, valueOfOr} from "puppit-shared/fn";
import {CONNECTED, INTERNAL_ID, ISSUES, PARTNER_CONTACTS} from "../fn/dict";
import React, {useEffect, useState} from "react";
import {connect, fetchPartner, mapToData} from "../fn/api";
import {descending, mergeLeft, minusOne, plusOne, toKeyedDiv} from "../fn/fn";
import {processingAtom} from "../fn/atomic";
import {useAtom} from "jotai";
import {toPartnerCard} from "../fn/partner-fn";
import VoteCard from "./vote/VoteCard";

const Partner = () => {

    const {partnerId} = useParams();

    const [partner, setPartner] = useState(undefined);
    const [votes, setVotes] = useState([]);
    const [processing, setProcessing] = useAtom(processingAtom);

    useEffect(() => {

        setProcessing(plusOne)
        const abort = new AbortController();
        fetchPartner(abort, partnerId).then(mapToData)
            .then(cloak(setPartner))
            .then(vo => setVotes(valueOfOr(ISSUES, [])(vo)))
            .finally(() => setProcessing(minusOne))
        return () => abort.abort();

        // eslint-disable-next-line
    }, [])

    const onAfterVote = v => {
        setVotes(votez => votez.map(vote => (votez[INTERNAL_ID] === v[INTERNAL_ID]) ? mergeLeft(votez, v) : vote));
    }

    if (isNotDefined(partner))
        return <></>

    const partnerContacts = coalesce(valueOf(PARTNER_CONTACTS)(partner), []);
    const connected = valueOf(CONNECTED)(partner)

    const handleConnectDisconnect = partnerId => {
        setProcessing(plusOne);
        connect(Number(partnerId), !connected).then(() => partner[CONNECTED] = !connected)
            .finally(() => setProcessing(minusOne));
    }


    return (
        <>
            {toPartnerCard(handleConnectDisconnect, false, processing)(partner, partnerContacts, 0)}

            <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-3">
                {votes.sort(descending(INTERNAL_ID))
                    .map(vo => <VoteCard data={vo} onAfterVote={onAfterVote}/>)
                    .map(toKeyedDiv("col mb-3", "vote"))}
            </div>
        </>
    )
}

export default Partner
