import React, {useEffect, useState} from 'react';
import {processingAtom, userAtom} from "../../fn/atomic";
import {useAtom} from "jotai";
import {isBlank, isDefined, valueOf, valueOfOr} from "puppit-shared/fn";
import {
    ceil,
    compose,
    floor,
    isNotProcessing,
    isProcessing,
    minusOne,
    plusOne,
    stripAndTrim,
    toProjectionOf,
    whatsAppLink,
    whatsAppQrCodeImage
} from "../../fn/fn";
import {COUNTRY_CODE, IS_MODAL, ON_CONTINUE, PROFILE_ID, REJECTION_REASON, ZIP_CODE} from "../../fn/dict";
import {NAME} from "puppit-shared/dict";
import {allCountryCodes, getCountryName} from "../../fn/locale";
import DocumentUpload from "./DocumentUpload";
import {
    accountVerificationStatus,
    previousVerificationWasNotRejected,
    previousVerificationWasRejected,
    userDetailsAreNotComplete
} from "../../fn/user";
import {updateUserProfile} from "../../fn/api";

const VerifyFlow = props => {

    const defaultFormData = {
        [NAME]: '',
        [COUNTRY_CODE]: 'GBR',
        [ZIP_CODE]: ''
    };

    const [user, ] = useAtom(userAtom);
    const [processing, setProcessing] = useAtom(processingAtom);
    const [formData, setFormData] = useState(defaultFormData);
    const isModal = valueOfOr( IS_MODAL, false )(props);
    const onContinue = valueOfOr( ON_CONTINUE, false )(props);

    const TOTAL_STEPS = 7;
    const PROCESSING_STEP = 6;

    const [currentStep, setCurrentStep] = useState(-1);

    useEffect(() => {

        if (isDefined(user)) {

            setFormData(f => {
                return {...f, ...user}
            });

            if (accountVerificationStatus(user).isUnknown())
                setCurrentStep(PROCESSING_STEP);
            else
                setCurrentStep(0);
        }

        // eslint-disable-next-line
    }, [user]);

    const handleInputChange = field => event => setFormData({...formData, [field]: event.target.value});

    const prev = () => setCurrentStep(compose(minusOne, floor(0)));
    const next = () => setCurrentStep(compose(plusOne, ceil(TOTAL_STEPS)));

    const doUpdateDetails = () => {

        setProcessing(plusOne);

        const vo = toProjectionOf([PROFILE_ID, NAME, COUNTRY_CODE, ZIP_CODE])(formData);
        vo[NAME] = vo[NAME].trim();
        vo[ZIP_CODE] = stripAndTrim(vo[ZIP_CODE]);

        updateUserProfile(vo)
            .then(next)
            .finally(() => setProcessing(minusOne));
    }


    const render = () => {

        switch (currentStep) {
            case 0:
                return <WelcomeStep formData={formData} onNext={next}/>;

            case 1:
                return <NameStep value={formData[NAME]}
                                 onChange={handleInputChange(NAME)}
                                 onPrev={prev}
                                 onNext={next}/>;
            case 2:
                return <CountryStep value={formData[COUNTRY_CODE]}
                                    onChange={handleInputChange(COUNTRY_CODE)}
                                    onPrev={prev}
                                    onNext={next}/>;
            case 3:
                return <ZipCodeStep value={formData[ZIP_CODE]}
                                    onChange={handleInputChange(ZIP_CODE)}
                                    onPrev={prev}
                                    onNext={next}/>;

            case 4:
                return <ConfirmationStep formData={formData}
                                         processing={processing}
                                         onPrev={prev}
                                         onNext={doUpdateDetails}/>;
            case 5:
                return <DocumentUpload onPrev={prev}
                                       onNext={next}/>;
            case 6:
                return <VerificationProcessingStep processing={processing} onContinue={onContinue}/>;
            default:
                return null;
        }
    };

    return (
        <div className='d-flex justify-content-center'>

            <div className={(isModal ? '' : 'mt-5 pt-5') + ' mw-verification '}>
                {render()}
            </div>

        </div>);
};


const WelcomeStep = ({formData, onNext}) => (

    <div className="card border-0">
        <div className="card-body">
            <h5 className="card-title h2">Verification</h5>
            <p className="card-text text-muted">To ensure the integrity and security of each vote, we ask all users to
                undergo a verification
                step. All documents are permanently deleted once processed.</p>

            <p hidden={previousVerificationWasRejected(formData) || userDetailsAreNotComplete(formData)}
               className="card-text text-muted">Please confirm that your details are correct and then click next to
                verify your account.</p>
            <p hidden={previousVerificationWasNotRejected(formData)} className="card-text text-boxes">Unfortunately we
                were unable to verify you account because {valueOf(REJECTION_REASON)(formData)}. Please amend your
                details or upload a new document.</p>

            <div className="d-flex mt-4 justify-content-between">
                <button className="btn btn-primary btn-block" onClick={onNext}>Let's go</button>
            </div>
        </div>
    </div>

);


const NameStep = ({value, onChange, onPrev, onNext}) => (

    <div className="card border-0">
        <div className="card-body">
            <h5 className="card-title h2">Name</h5>
            <p className="card-text text-muted">Please enter your full name, and click next.</p>

            <input className="form-control" type="text" id="name" value={value} onChange={onChange}
                   placeholder="Enter your full name"/>
            <div className="d-flex mt-4 justify-content-between">
                <button className="btn btn-outline-dark" onClick={onPrev}>Back</button>
                <button disabled={isBlank(value)} className="btn btn-primary" onClick={onNext}>Next</button>
            </div>
        </div>
    </div>
);

const CountryStep = ({value, onChange, onPrev, onNext}) => (
    <div className="card border-0 ">
        <div className="card-body">
            <h5 className="card-title h2">Country</h5>
            <p className="card-text text-muted">Select the country which you are able to vote, and click next.</p>

            <select id="country"
                    value={value}
                    className="form-control"
                    onChange={onChange}>
                {allCountryCodes.map((code, idx) => <option key={idx} value={code}>
                    {getCountryName(code)}</option>)}
            </select>


            <div className="d-flex mt-4 justify-content-between">
                <button className="btn btn-outline-dark" onClick={onPrev}>Back</button>
                <button className="btn btn-primary" onClick={onNext}>Next</button>
            </div>
        </div>
    </div>
);

const ZipCodeStep = ({value, onChange, onPrev, onNext}) => (
    <div className="card border-0">
        <div className="card-body">
            <h5 className="card-title h2">Zipcode</h5>
            <p className="card-text text-muted">Please enter your zipcode or postcode</p>

            <input className="form-control" type="text" id="name" value={value} onChange={onChange}/>
            <div className="d-flex mt-4 justify-content-between">
                <button className="btn btn-outline-dark" onClick={onPrev}>Back</button>
                <button disabled={isBlank(value)}  className="btn btn-primary" onClick={onNext}>Next</button>
            </div>
        </div>
    </div>
);

const ConfirmationStep = ({formData, processing, onPrev, onNext}) => (

    <div className="card border-0">
        <div className="card-body">
            <h5 className="card-title h2">Everything correct?</h5>

            <p className="text-success font-italic">
                {valueOf(NAME)(formData)}<br/>
                {getCountryName(valueOf(COUNTRY_CODE)(formData)) + ', ' + valueOf(ZIP_CODE)(formData)}
            </p>

            <div className="d-flex mt-4 justify-content-between">
                <button disabled={isProcessing(processing)} className="btn btn-outline-dark"
                        onClick={onPrev}>Back
                </button>
                <button disabled={userDetailsAreNotComplete(formData) || isProcessing(processing)}
                        className="btn btn-primary"
                        onClick={onNext}>
                    <span hidden={isNotProcessing(processing)}>
                        <span className="spinner-grow spinner-grow-sm"/>&nbsp;&nbsp;</span>Next
                </button>
            </div>
        </div>
    </div>
);

const VerificationProcessingStep = ({processing, onContinue}) => (
    <div className="card border-0">
        <div className="card-body">
            <h1 className="display-4 logo  mb-4"><i className="bi bi-heart"/></h1>
            <h5 className="card-title h2">Thank you.</h5>
            <p className="card-text text-muted">We will get you up and running as soon as we can, for support
            and help please join our growing community:</p>
            <div className='d-flex justify-content-center my-4'>
                <img src={whatsAppQrCodeImage()} className="img mw-40" alt='Whats App Community'/>
            </div>
            <div className='d-flex justify-content-center'>
                <a href={whatsAppLink()} target='_wa' className='btn btn-lg btn-primary '>
                    <i className="bi bi-whatsapp"/>&nbsp;Join The Community</a>
            </div>

            <div className="d-flex mt-5 pt-5 justify-content-between">
                <button disabled={isProcessing(processing)} className="btn btn-outline-secondary btn-block"
                        onClick={onContinue}>Continue
                </button>
            </div>
        </div>
    </div>
);


export default VerifyFlow;
