import { clone, isBlank, isDefined, isNotBlank, isNotDefined, valueOf } from "puppit-shared/fn";
import * as uuid from "uuid";
import { coalesceTrilean } from "./tnew";
import { PROFILE_VERIFIED } from "./dict";


export const release = () => process.env[ 'REACT_APP_RELEASE' ] + ' ' + process.env[ 'REACT_APP_RELEASE_NAME' ]


export const whatsAppQrCodeImage = () => 'https://blocvote.sgp1.cdn.digitaloceanspaces.com/bv-wa-qr.png';
export const whatsAppLink = () => 'https://chat.whatsapp.com/EGulWmP3biQElm0tyfmxLJ';
export const helpVideoLink = () => 'https://www.blocvote.org/help';
export const xLink = () => 'https://twitter.com/BLOCVOTEORG';

export const setterFor = key => value => old => {

    const newVo = clone( old || {} );
    newVo[ key ] = value;
    return newVo;
}

export const compose = ( ...functions ) => x => functions.reduce( ( value, func ) => func( value ), x );

export const isProcessing = x => x > 0;
export const isNotProcessing = x => x === 0;
export const plusOne = x => x + 1;
export const minusOne = x => x - 1;
export const floor = f => v => ( v < f ) ? f : v;
export const ceil = c => v => ( v > c ) ? c : v;
export const not = x => !x;

export const getterFor = data => key => valueOf( key )( data );

export const isEmptyObject = obj => Object.keys( obj ).length === 0;


export const toProjectionOf = keys => obj => {

    let projection = {}

    for ( let key of keys )
        projection[ key ] = obj[ key ];

    return projection;
}

export const toComplementOf = keys => obj => {

    const complement = {};

    for ( const key in obj )
        if ( !keys.includes( key ) ) complement[ key ] = obj[ key ];

    return complement;
}

export const toMapBy = key => ( accumulator, value ) => {
    accumulator[ value[ key ] ] = value;
    return accumulator;
}


export const distinctOn = ( key, seen ) => o => {
    const value = valueOf( key )( o );
    const allowIt = seen.indexOf( value ) === -1;

    if ( allowIt )
        seen.push( value );

    return allowIt;
}

export const capitaliseFirstLetter = str => {
    return str.charAt( 0 ).toUpperCase() + str.substring( 1 );
}

export const fromEvent = fn => e => fn( e.target.value );

export const validate = regex => e => {

    if ( e.key === 'Backspace' )
        return;

    if ( !regex.exec( e.key ) )
        e.preventDefault();
}

export const and = ( fn1, fn2 ) => vo => fn1( vo ) && fn2( vo )
export const fieldIsBlank = key => vo => isBlank( valueOf( key )( vo ) )
export const fieldIsNotBlank = key => vo => isNotBlank( valueOf( key )( vo ) )
export const has = key => vo => isDefined( vo ) && isDefined( vo[ key ] )

export const textRegex = /^[^'\\|^]*$/

export const ascending = key => ( a, b ) => valueOf( key )( a ) >= valueOf( key )( b ) ? 1 : -1;
export const descending = key => ( a, b ) => valueOf( key )( a ) >= valueOf( key )( b ) ? -1 : 1;

export const toKeyedDiv = ( clazz, prefix ) => ( vo, idx ) => <div key={prefix + '-' + idx} className={clazz}>{vo}</div>

export const textToParagraph = text => {
    const id = uuid.v4();
    return ( text || '' ).split( '\n' )
                         .map( ( p, idx ) => <p key={id + idx}>{p}</p> );
}


export const profileVerified = p => coalesceTrilean( p[ PROFILE_VERIFIED ] );

export const mergeLeft = ( x, y ) => Object.assign( {}, x, y );
export const mergeRight = ( x, y ) => Object.assign( {}, y, x );
export const trileanValueOf = key => vo => coalesceTrilean( valueOf( key )( vo ) )


export const mergeLeftWith = x => y => mergeLeft( x, y )

export const stripAndTrim = str => isNotDefined( str ) ? undefined : str.trim().replace(/\s/g, '').toUpperCase();

export const differenceInDays = differenceInMillis => Math.floor(differenceInMillis / (1000 * 3600 * 24));
