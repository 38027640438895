import React, {useEffect, useState} from "react";
import {connect, fetchAvailablePartnersForVoter, mapToData} from "../fn/api";
import {isNotDefined, valueOf} from "puppit-shared/fn";
import {minusOne, plusOne, toKeyedDiv} from "../fn/fn";
import {processingAtom} from "../fn/atomic";
import {useAtom} from "jotai";
import {toPartnerCard} from "../fn/partner-fn";
import {CONNECTED, PARTNER_ID} from "../fn/dict";
import PageHeading from "./PageHeading";

const Partners = () => {

        const [partners, setPartners] = useState([])
        const [processing, setProcessing] = useAtom(processingAtom)


        const handleConnectDisconnect = partnerId => {

            setProcessing(plusOne);
            const partnerIdx = partners.findIndex(obj => obj[PARTNER_ID] === partnerId);
            const connected = valueOf(CONNECTED)(partners[partnerIdx])

            connect(Number(partnerId), !connected)
                .then(() => setPartners(ps => {
                    ps[partnerIdx][CONNECTED] = !connected;
                    return ps;
                }))
                .finally(() => setProcessing(minusOne));
        }

        useEffect(() => {

            const abort = new AbortController();
            setProcessing(plusOne)
            fetchAvailablePartnersForVoter(abort).then(mapToData)
                .then(setPartners)
                .finally(() => setProcessing(minusOne))
            return () => abort.abort();

            // eslint-disable-next-line
        }, [])

        if (isNotDefined(partners))

            return <></>

        return (
            <>
                <PageHeading icon={<i className='rounded-circle p-2 bg-light bi bi-clock-history text-primary'/>}
                             heading='Partners'
                             lead='Our partners engage with the community and create votes on local and national issues.'/>

                <div className="row bg-light row-cols-1 row-cols-md-2 g-3">
                    {partners.map(toPartnerCard(handleConnectDisconnect, true, (processing > 0)))
                        .map(toKeyedDiv("col mb-3", "partner"))}
                </div>
            </>
        )
    }
;

export default Partners;
