import React, {useEffect, useState} from "react";
import {loggedInAtom, processingAtom, userAtom} from "../../fn/atomic";
import {useAtom} from "jotai";
import VotePanel from "./VotePanel";
import {CLOSING_SOON, IS_ELIGIBLE, ON_AFTER_VOTE, ON_LOGIN, VOTE, ZONE_NAME} from "../../fn/dict";
import {doNothing, isDefined, valueOf, valueOfOr} from "puppit-shared/fn";
import VoteResultsPanel from "./VoteResultsPanel";
import {isNotProcessing, isProcessing, profileVerified, trileanValueOf} from "../../fn/fn";
import {hasVoted, voteIsClosed, voteIsOpen} from "../../fn/vote-fn";

const HaveYourSayPanel = params => {

    const onAfterVote = valueOfOr(ON_AFTER_VOTE, doNothing)(params);
    const voteNowClick = valueOfOr(ON_LOGIN)(params);
    const closingSoon = valueOfOr(CLOSING_SOON, false)(params);
    const vote = valueOf(VOTE)(params);
    const isEligible = trileanValueOf(IS_ELIGIBLE)(vote);

    const [loggedIn,] = useAtom(loggedInAtom)
    const [user,] = useAtom(userAtom)
    const [processing,] = useAtom(processingAtom)
    const [message, setMessage] = useState(undefined)
    const [component, setComponent] = useState(<></>)


    useEffect(() => {

        setMessage(undefined);

        //clog(1);
        let component;
        if (voteIsClosed(vote)) {
            //  clog(2);
            component = <VoteResultsPanel vote={vote}/>
        } else if (loggedIn.isNotTrue()) {
            //clog(3);
            component = <button disabled={isProcessing(processing)}
                                className='btn btn-block btn-primary'
                                onClick={voteNowClick}>Vote Now</button>
        } else if (loggedIn.and(profileVerified(user)).isFalse()) {
            //clog(4);
            component = <VoteResultsPanel vote={vote}/>
            setMessage("Account verification required");
        } else if (loggedIn.and(profileVerified(user)).isUnknown()) {
            //clog(5);
            component = <VoteResultsPanel vote={vote}/>
            setMessage("Verification pending");
        } else if (loggedIn.and(isEligible.not()).isTrue()) {
            //clog('6 ' + isEligible.not().toString());
            component = <VoteResultsPanel vote={vote}/>
            setMessage("This vote is for " + valueOf(ZONE_NAME)(vote) + "  residents only");
        } else if (loggedIn.and(isEligible).and(hasVoted(vote)).isTrue()) {
            //clog(7);
            component = <VoteResultsPanel vote={vote}/>
        } else {
            //clog(8);
            component = <VotePanel vote={vote} onAfterVote={onAfterVote}/>;
        }

        setComponent(component);

    }, [vote, user, processing, loggedIn, isEligible, voteNowClick, onAfterVote]);


    let messageAlert = <></>;
    if (isDefined(message) && isNotProcessing(processing))
        messageAlert = <div className="alert alert-primary d-flex align-items-center mb-3" role="alert">
            {message}
        </div>

    return (
        <div>
            <div className='d-flex justify-content-between mb-1'>
                <h4 className='mb-0'><b>Have your say</b></h4>

                <div className='text-muted small'>
                    <span
                        className={closingSoon ? 'badge bg-danger text-white p-1 px-2' : ' d-none '}>closing soon</span>
                    <span
                        className={voteIsOpen(vote) ? ' d-none ' : ' badge bg-dark text-white p-1 px-2 '}>closed</span>
                </div>

            </div>
            <div className="pb-3">In a direct democracy every vote counts</div>
            {messageAlert}
            {component}
        </div>
    )
}
export default HaveYourSayPanel
