import { Trilean } from "dahai-mvl/dist/main/ts/Trilean";
import { equals } from "dahai-mvl/dist/main/ts/Trilean";

export const coalesceTrilean = t => {

    if ( t === undefined || t === null || t  === "unknown" || t === "maybe" || t === Trilean.U )
    return Trilean.U;
else if ( t  === "T" || t  === "true" || t  === 'yes' || t === Trilean.T )
    return Trilean.T;
else if ( t  === "F" || t  === "false" || t  === 'no' || t === Trilean.F )
    return Trilean.F;
else if ( typeof t === "boolean" && t )
        return Trilean.T;
    else if ( typeof t === "boolean" && !t )
        return Trilean.F;
    else
        return Trilean.U;
}

export const trileanWhen = ( t, u, f ) => trilean => {
    const truth = coalesceTrilean( trilean );
    return truth.isTrue() ? t : truth.isUnknown() ? u : f;
}


export const trileanIs = (k,v) => o =>  equals( o|{}[k], v  );
