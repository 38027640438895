import { valueOfOr } from "puppit-shared/fn";

const Splash = props => {

    const icon = valueOfOr( 'icon', <i className="bi bi-box"/>)( props )
    const title = valueOfOr( 'title', 'BLOCVOTE')( props )
    const text = valueOfOr( 'text', 'DELIVERING DIRECT DEMOCRACY')( props )

    return <div className="vh-80 d-flex align-items-center justify-content-center pt-5">

            <div className="text-center py-4">
                <h1 className="display-1 logo">{icon}</h1>

                <h2>{title}</h2>
                <div className="fade-text text-muted mx-3 ">{text}</div>
            </div>
    </div>
};

export default Splash;
