import {useAtom} from "jotai";
import {loggedInAtom, profileReloadAtom, sessionTokenAtom, userAtom} from "../fn/atomic";
import Splash from "./Splash";
import LoginFlow from "./login/LoginFlow";
import {plusOne, profileVerified} from "../fn/fn";
import VerifyFlow from "./verify/VerifyFlow";
import {isNotDefined} from "puppit-shared/fn";

const Secure = ( { children } ) => {

    const [user, ] = useAtom( userAtom );
    const [loggedIn,] = useAtom( loggedInAtom )
    const [, setProfileReload] = useAtom( profileReloadAtom )
    const [sessionToken,] = useAtom( sessionTokenAtom );

    if ( loggedIn.isUnknown() )
        return <Splash/>
    else if ( loggedIn.isFalse() || isNotDefined( sessionToken ))
        return <LoginFlow/>
    else if ( profileVerified( user ).isFalse() )
        return <VerifyFlow onContinue={()=> setProfileReload( plusOne )}/>
    else
        return children;

};

export default Secure;
