import { setterFor } from "./fn";
import { clone, valueOf } from "puppit-shared/fn";
import { VOTE_ACCOUNT_CYPHER } from "./dict";

export const updateUserContext = ( userEmail, userContext, setBlocvoteUserContext ) => vo => {

    const withUserContext = setterFor( userEmail );
    const voteAccountCypher = valueOf( VOTE_ACCOUNT_CYPHER )( vo );

    const userContextClone = clone( userContext || {} );
    userContextClone[ VOTE_ACCOUNT_CYPHER ] = voteAccountCypher;

    setBlocvoteUserContext( withUserContext( userContextClone ) )
};

