import React from 'react';
import { Helmet } from 'react-helmet';

const Metadata = ({ title, description, url, image }) => {
    return (
        <Helmet>
            {title && <title>{title}</title>}
            {description && <meta name="description" content={description} />}

            {/* Open Graph / Facebook */}
            {<meta property="og:type" content='article' />}
            {url && <meta property="og:url" content={url} />}
            {title && <meta property="og:title" content={title} />}
            {description && <meta property="og:description" content={description} />}
            {image && <meta property="og:image" content={image} />}

            {/* Twitter */}
            {image && <meta property="twitter:card" content={image} />}
            {url && <meta property="twitter:url" content={url} />}
            {title && <meta property="twitter:title" content={title} />}
            {description && <meta property="twitter:description" content={description} />}
            {image && <meta property="twitter:image" content={image} />}
        </Helmet>
    );
};

export default Metadata;
