import {isNotDefined, valueOf} from "puppit-shared/fn";
import {
    CONNECTED,
    INDEPENDENT,
    PARTNER_BIO,
    PARTNER_ID,
    PARTNER_IMAGE,
    PARTNER_NAME,
    PARTNER_TITLE,
    PARTNER_TYPE,
    PARTNER_URL
} from "./dict";
import {Link} from "react-router-dom";
import {thePartnerPage} from "./api";

export const renderFeedback = words => words?.toString()
    .replace(/Failed\W*/i, '')
    .replace(/Passed\W*/i, '')
    .trim();

export const toPartnerCard = (onConnectClick, showLink, processing) => (partnerDetails, idx) => {

    const partnerId = valueOf(PARTNER_ID)(partnerDetails);
    const partnerTitle = valueOf(PARTNER_TITLE)(partnerDetails);
    const partnerImage = valueOf(PARTNER_IMAGE)(partnerDetails);
    const partnerName = valueOf(PARTNER_NAME)(partnerDetails);
    const partnerBio = valueOf(PARTNER_BIO)(partnerDetails);
    const partnerType = valueOf(PARTNER_TYPE)(partnerDetails);
    const connected = valueOf(CONNECTED)(partnerDetails);
    const partnerUrl = valueOf(PARTNER_URL)(partnerDetails);

    const connectedIcon = isConnected => isConnected ? <i className="h4 bi bi-plug-fill text-success"/> :
        <i className="h4 bi bi-plug text-secondary"/>

    const link = showLink ? <Link className={processing ? 'disabled-link' : ''}
                                  to={thePartnerPage(partnerId)}
                                  style={{textDecoration: 'none'}}>
        <i className="h4 bi bi-arrow-right-circle"/>
    </Link> : <div></div>;

    return (

        <div key={'pc-' + idx} className="card shadow-sm border-0 mb-4" style={{'minHeight': '18em'}}>
            <div className="card-body d-flex flex-column justify-content-around ">

                <div className="d-flex justify-content-between align-content-center">
                    <img src={partnerImage}
                         className={"card-img mb-2" + (partnerType === INDEPENDENT ? " rounded-circle " : "")}
                         style={{height: '50px', width: '50px'}}
                         alt={partnerName}/>

                    <a hidden={isNotDefined(partnerUrl)} href={partnerUrl} target='_new' className="h5 pt-1"><i className=" bi bi-box-arrow-up-right"></i></a>
                </div>

                <h3 className="mb-0 pb-0">{partnerName}</h3>
                <p className={"text-muted"}>{partnerTitle}</p>
                <div className="mt-2 ">{partnerBio}</div>

                <div className="d-flex justify-content-between mt-3">
                    <div className={'btn btn-link p-0 ' + (processing ? 'disabled-link' : '')}
                         onClick={() => onConnectClick(partnerId)}>{connectedIcon(connected)}</div>
                    {link}
                </div>
            </div>
        </div>);

}
