import { atomWithStorage } from "jotai/utils";
import { BLOCVOTE_DEVICE_CONTEXT, SESSION_TOKEN } from "./dict";
import { atom } from "jotai";
import * as uuid from "uuid";
import { Trilean } from "dahai-mvl/dist/main/ts/Trilean";

export const TO_USER_DEFAULT = { };

export const processingAtom = atom( 0 );
export const userAtom = atom( TO_USER_DEFAULT );
export const deviceIdAtom = atom( uuid.v4() );
export const loggedInAtom = atom( Trilean.U );
export const profileReloadAtom = atom( 0 );
export const voteAccountValidAtom = atom( Trilean.U );
export const sessionTokenAtom = atomWithStorage( SESSION_TOKEN, undefined);
export const deviceContextAtom = atomWithStorage( BLOCVOTE_DEVICE_CONTEXT, {} );

