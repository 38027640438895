import React from "react";
import {truncate, valueOf, valueOfOr} from "puppit-shared/fn";
import {INTERNAL_ID, ISSUE_QUESTION, ISSUE_SUMMARY, PARTNER_NAME, VOTE_CLOSE_TIMESTAMP, ZONE_NAME} from "../../fn/dict";
import {canVote, isClosingSoon, voteIsOpen} from "../../fn/vote-fn";
import VoteResultsPanel from "./VoteResultsPanel";
import {DATA} from "puppit-shared/dict";
import {Link} from "react-router-dom";
import {thePublicVotePage} from "../../fn/navigation-fn";
import {differenceInDays, textToParagraph} from "../../fn/fn";

const VoteCard = props => {

    const vo = valueOf(DATA)(props)
    const internalId = valueOf(INTERNAL_ID)(vo);
    const partnerName = valueOf(PARTNER_NAME)(vo);
    const issueQuestion = valueOf(ISSUE_QUESTION)(vo);
    const issueSummary = valueOf(ISSUE_SUMMARY)(vo);
    const zoneName = valueOf(ZONE_NAME)(vo);

    const voteExpires = valueOfOr(VOTE_CLOSE_TIMESTAMP, undefined)(vo);
    const closeDate = new Date(voteExpires);
    const difference = closeDate.getTime() - new Date();

    const panel = canVote(vo)
        ? <Link to={thePublicVotePage(internalId)} className='w-100 btn btn-primary btn-block'>Vote Now</Link>
        : <>
            <VoteResultsPanel vote={vo}/>
            <div className='text-muted small text-right'>
                <Link to={thePublicVotePage(internalId)}>View details <i className="bi bi-arrow-right"></i></Link>
            </div>
        </>

    return <>
        <div className="card border-1 shadow-sm">
            <div className="card-body">
                <h5 className="card-title mb-1">{issueQuestion}</h5>
                <div className="card-text">
                    <div className='d-flex justify-content-between mb-2'>
                        <div className='text-muted small'>{partnerName}, {zoneName}</div>
                        <div className='text-muted small'>
                    <span
                        className={isClosingSoon(differenceInDays(difference))  ? 'badge bg-danger text-white p-1 px-2' : ' d-none '}>closing soon</span>
                            <span
                                className={voteIsOpen(vo) ? ' d-none ' : ' badge bg-dark text-white p-1 px-2 '}>closed</span>
                        </div>
                    </div>

                    <div className="card-text text-muted small">{textToParagraph(truncate(128)(issueSummary))}</div>
                    {panel}

                </div>

            </div>
        </div>
    </>
}

export default VoteCard
