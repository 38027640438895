import { useAtom } from "jotai";
import { Link } from "react-router-dom";
import React, { useState } from "react";
import { sessionTokenAtom, userAtom, voteAccountValidAtom } from "../../fn/atomic";
import { ADMIN, KYC_OPS_PAGE, PARTNER } from "../../fn/dict";
import { MANAGE_CONNECTIONS_PAGE, MANAGE_VOTES_PAGE } from "../../fn/navigation-fn";
import PageHeading from "../PageHeading";
import { hasRole } from "../../fn/user";
import { accountIcon, checkIcon, circleIcon, supportIcon } from "../../fn/icons";
import MultiModal from "./MultiModal";
import ActivationCodeModal from "./ActivationCodeModal";
import AccountDetails from "./AccountDetails";
import SupportDetails from "./SupportDetails";
import { release } from "../../fn/fn";

const Settings = () => {

    const [user,] = useAtom( userAtom );
    const [, setSessionToken] = useAtom( sessionTokenAtom );
    const [voteAccountValid,] = useAtom( voteAccountValidAtom );
    const [modal, setModal] = useState( undefined );

    const SECURITY_MODAL = 'security';
    const SUPPORT_MODAL = 'support';
    const ACCOUNT_MODAL = 'account';

    const showModal = modal => () => setModal( modal );
    const hideModal = () => setModal( undefined );
    const showingModal = m => modal === m;

    const logout = () => setSessionToken( undefined );

    return <>
        <MultiModal isShowing={showingModal( SECURITY_MODAL )} hide={hideModal}>
            <ActivationCodeModal onHide={hideModal}/>
        </MultiModal>

        <MultiModal isShowing={showingModal( ACCOUNT_MODAL )} hide={hideModal}>
            <AccountDetails onHide={hideModal}/>
        </MultiModal>

        <MultiModal isShowing={showingModal( SUPPORT_MODAL )} hide={hideModal}>
            <SupportDetails onHide={hideModal}/>
        </MultiModal>

        <PageHeading icon={<i className='rounded-circle p-2 bg-light bi bi-gear text-primary'/>} heading='Settings'
                     lead="Your account and security details.  Don't forget to store your voting account password."/>

        <ul className="w-100 list-group list-group-flush pb-4">

            <li className="list-group-item border-0 mt-2 px-0">
                <button onClick={showModal( ACCOUNT_MODAL )}
                        className="py-3 btn border-0 bg-light btn-outline-dark btn-block d-flex align-items-center justify-content-between rounded">
                    <div>{accountIcon( 'h5 mr-3 text-success' )}Account</div>
                    <div className="mt-1"><i className="h5 bi bi bi-arrow-right"/></div>
                </button>
            </li>

            <li className="list-group-item border-0 mt-2 px-0">
                <button onClick={showModal( SECURITY_MODAL )}
                        className="py-3 btn border-0 bg-light btn-outline-dark btn-block d-flex align-items-center justify-content-between rounded">
                    <div>{checkIcon( 'h5 mr-3' )( voteAccountValid )}Security</div>
                    <div className="mt-1"><i className="h5 bi bi bi-arrow-right"/></div>
                </button>
            </li>

            <li className="list-group-item border-0 mt-2 px-0">
                <button onClick={showModal( SUPPORT_MODAL )}
                        className="py-3 btn border-0 bg-light btn-outline-dark btn-block d-flex align-items-center justify-content-between rounded">
                    <div>{supportIcon( 'h5 text-success mr-3' )}Community and Support</div>
                    <div className="mt-1"><i className="h5 bi bi bi-arrow-right"/></div>
                </button>
            </li>

        </ul>

        <ul className="list-group list-group-flush" hidden={!hasRole( user, PARTNER )}>

            <li className="list-group-item border-0 mt-2 px-0">
                <Link to={MANAGE_VOTES_PAGE}
                      className="py-3 btn border-0 bg-light btn-outline-dark btn-block d-flex align-items-center justify-content-between rounded">
                    <div>{circleIcon( 'h5 text-info mr-3' )}Manage Votes</div>
                    <div className="mt-1"><i className="h5 bi bi bi-arrow-right"/></div>
                </Link>
            </li>

            <li className="list-group-item border-0  mt-2 px-0">
                <Link to={MANAGE_CONNECTIONS_PAGE}
                      className="py-3 btn border-0 bg-light btn-outline-dark btn-block d-flex align-items-center justify-content-between rounded">
                    <div>{circleIcon( 'h5 text-info mr-3' )}Manage Connections</div>
                    <div className="mt-1"><i className="h5 bi bi bi-arrow-right"/></div>
                </Link>
            </li>

        </ul>

        <ul className="list-group list-group-flush" hidden={!hasRole( user, ADMIN )}>

            <li className="list-group-item border-0  mt-2 px-0">
                <Link to={KYC_OPS_PAGE}
                      className="py-3 btn border-0 bg-light btn-outline-dark btn-block d-flex align-items-center justify-content-between rounded">
                    <div>{circleIcon( 'h5 text-info mr-3' )}Manage Verifications</div>
                    <div className="mt-1"><i className="h5 bi bi bi-arrow-right"/></div>
                </Link>
            </li>

        </ul>

        <ul className="list-group list-group-flush">

            <li className="list-group-item border-0  mt-2 px-0">
                <button onClick={logout} className="btn btn-outline-danger btn-block rounded">
                    <div>Logout</div>
                </button>
            </li>

            <li className="list-group-item border-0 mt-2 px-0 text-center small mb-4">
                <pre>Release {release()}</pre>

            </li>
        </ul>
    </>

};

export default Settings;
