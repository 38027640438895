import React from "react";
import { isBlank, isNotDefined, valueOf } from "puppit-shared/fn";
import { DATA } from "puppit-shared/dict";
import { getterFor } from "../fn/fn";
import {
    CAN_EDIT,
    ISSUE_LINK,
    ISSUE_QUESTION,
    ISSUE_SUMMARY,
    ON_DELETE,
    ON_EDIT,
    ON_PUBLISH,
    PROCESSING
} from "../fn/dict";
import { Dropdown } from "react-bootstrap";

const PartnerDraftVoteItem = params => {

    const data = valueOf( DATA )( params );

    const getData = getterFor( data );
    const getParams = getterFor( params );

    const moreInformation =
        isBlank( getData( ISSUE_LINK ) )
            ? <></>
            :
            <a href={getData( ISSUE_LINK )} className="ml-3 text-info text-decoration-none" target="_out">More
                information</a>

    const menu = getParams( CAN_EDIT )
        ? <Dropdown className="pr-3">

            <Dropdown.Toggle disabled={getParams( PROCESSING ) || false} variant="outline-dark" id="dropdown-basic">
                <i className="bi bi-list"/>
            </Dropdown.Toggle>

            <Dropdown.Menu>
                <Dropdown.Item disabled={false} onClick={getParams( ON_EDIT )}>Edit</Dropdown.Item>
                <Dropdown.Item disabled={false} onClick={getParams( ON_PUBLISH )}>Publish</Dropdown.Item>
                <Dropdown.Divider/>
                <Dropdown.Item disabled={false} onClick={getParams( ON_DELETE )}>Delete</Dropdown.Item>
            </Dropdown.Menu>

        </Dropdown>

        : <></>


    if ( isNotDefined( data ) )
        return <></>

    return (
        <>
            <li className="list-group-item px-0 border-0">
                <div className="card pt-3 pb-2 " style={{ borderWidth: '3px'}}>
                    <div className="d-flex justify-content-between gap-2">
                        <div>
                            <div>
                                <div className="ml-3 mb-2">
                                    <b className="text-muted" style={{ fontSize: '1.3rem' }}>{getData( ISSUE_QUESTION )}</b>
                                </div>
                            </div>
                            <div className="ml-3 mb-1">{getData( ISSUE_SUMMARY )}</div>
                            {moreInformation}
                        </div>
                        {menu}
                    </div>
                </div>
            </li>
        </>
    )
}

export default PartnerDraftVoteItem
