import { Modal } from "react-bootstrap";
import React from "react";
import { CLOSE, IS_OPEN } from "../fn/dict";
import { doNothing, valueOf, valueOfOr } from "puppit-shared/fn";
import { SETTINGS_PAGE } from "../fn/navigation-fn";
import { Link } from "react-router-dom";
import PageHeading from "./PageHeading";
import { securityIcon } from "../fn/icons";
import { Trilean } from "dahai-mvl/dist/main/ts/Trilean";

const KycRequiredModal = props => {
    const isOpen = valueOf( IS_OPEN )( props );
    const doClose = valueOfOr( CLOSE, doNothing )( props )

    return (
        <Modal className="shadow-sm"
               centered
               show={isOpen}
               onHide={doClose}
               animation={true}>


            <Modal.Body>
                <PageHeading icon={securityIcon()( Trilean.T )}
                             heading='Security'
                             lead='There are some issues with your security settings that need to be resolved before you can proceed.'/>

                <div className='d-flex justify-content-between'>
                    <button onClick={doClose} className="btn  btn-outline-dark mt-3 rounded">
                        Close
                    </button>
                    <Link to={SETTINGS_PAGE} className="btn  btn-outline-primary mt-3 rounded">
                        Go to Settings
                    </Link>
                </div>

            </Modal.Body>

        </Modal>
    )
}

export default KycRequiredModal