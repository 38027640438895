import React, { useEffect, useState } from "react";
import { cloak, first, valueOf } from "puppit-shared/fn";
import { MESSAGE, PARTNER_ID, PARTNER_NAME, PROCESSING } from "../fn/dict";
import { fetchAvailablePartnersForPartner, mapToData } from "../fn/api";
import { getterFor, minusOne, plusOne } from "../fn/fn";

const PartnerSelector = props => {

    const get = getterFor( props );

    const processing = get( PROCESSING );
    const setProcessing = get( "setProcessing" );
    const selectedPartner = get( "selectedPartner" );
    const setSelectedPartner = get( "setSelectedPartner" );

    const [availablePartners, setAvailablePartners] = useState( [] );

    const isPartnerSelected = partnerId => selectedPartner === partnerId;

    useEffect( () => {

        setProcessing( plusOne )

        const abort = new AbortController();
        fetchAvailablePartnersForPartner( abort ).then( mapToData )
                                                 .then( cloak( setAvailablePartners ) )
                                                 .then( partners => setSelectedPartner( valueOf( PARTNER_ID )( first( partners ) ) ) )
                                                 .finally( () => setProcessing( minusOne ) )
        return () => abort.abort();;

        // eslint-disable-next-line
    }, [] )

    const partnerFactory = ( vo, idx ) => {

        const partnerId = valueOf( PARTNER_ID )( vo );
        const partnerName = valueOf( PARTNER_NAME )( vo );

        let clazz = isPartnerSelected( partnerId ) ? ' active ' : '';
        clazz += processing ? ' disabled ' : '';

        return <button
            key={idx}
            onClick={() => setSelectedPartner(  partnerId )}
            className={" list-group-item list-group-item-action " + clazz}>{partnerName}</button>
    }





    return <div>
        <b style={{fontSize: '1.7rem'}}>Partner</b>
        <p>{get( MESSAGE )}</p>
        <ul className="list-group ">
            {availablePartners.map( partnerFactory )}
        </ul>
    </div>
}

export default PartnerSelector;
