import React, { useEffect, useState } from "react";
import { cloak, first, isNotDefined, valueOf } from "puppit-shared/fn";
import { MESSAGE, ZONE_ID, ZONE_NAME, ZONE_ORDER } from "../fn/dict";
import { fetchPartnerZones, mapToData } from "../fn/api";
import { ascending, getterFor, minusOne, plusOne } from "../fn/fn";


const ZoneSelector = props => {

    const get = getterFor( props );

    const partnerId = get( "partnerId" );
    const processing = get( "processing" );
    const setProcessing = get( "setProcessing" );
    const selectedZone = get( "selectedZone" );
    const setSelectedZone = get( "setSelectedZone" );

    const [availableZones, setAvailableZones] = useState( [] );

    const isZoneSelected = zoneId => selectedZone === zoneId;

    useEffect( () => {

        if ( isNotDefined( partnerId ) )
            return;

        setProcessing( plusOne )

        const abort = new AbortController();
        fetchPartnerZones( abort, partnerId ).then( mapToData )
                                             .then( cloak( setAvailableZones ) )
                                             .then( zones => setSelectedZone( valueOf( ZONE_ID )( first( zones ) ) ) )
                                             .finally( () => setProcessing( minusOne ) )

        return () => abort.abort();
        ;

        // eslint-disable-next-line
    }, [partnerId] )

    const zoneFactory = ( vo, idx ) => {

        const zoneId = valueOf( ZONE_ID )( vo );
        const zoneName = valueOf( ZONE_NAME )( vo );
        let clazz = isZoneSelected( zoneId ) ? ' active ' : '';
        clazz += processing ? ' disabled ' : '';

        return <button onClick={() => setSelectedZone( zoneId )}
                       key={idx}
                       className={"list-group-item list-group-item-action " + clazz}>{zoneName}</button>
    }


    return <div className="w-100">
        <b style={{ fontSize: '1.7rem' }}>Select your audience</b>
        <p>{get( MESSAGE )}</p>
        <ul className="list-group ">
            {availableZones.sort( ascending( ZONE_ORDER ) ).map( zoneFactory )}
        </ul>
    </div>
}

export default ZoneSelector;

